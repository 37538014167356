import React, { useState } from "react";

function randomNumberInRange(min, max) {
   // 👇️ get number between min (inclusive) and max (inclusive)
   return Math.floor(Math.random() * (max - min + 1)) + min;
}
let i = randomNumberInRange(0, 2);
const Testimonials = () => {
   const testimonials = [
      {
         name: [
            <>
               <a href="https://www.linkedin.com/in/andrei-craciun" target="_blank" rel="noopener noreferrer">
                  <h3>
                     Andrei Crăciun
                     <img src="./images/box-arrow-white.svg" alt="" />
                  </h3>
               </a>
            </>,
         ],
         job: "Founder, Hede Strategic",
         image: "andrei-craciun.jpeg",
         testimonial:
            "The best IT firm I've ever had the pleasure of working with. We had really bad experiences with past developers, but these guys are something else, highly recommend! Our project consisted of a mobile app that allows logistics firms to better optimize their drivers' routes and deliveries, both for Android and iOS - no issues for 2Morrow. They were faster than we expected.",
      },
      {
         name: [
            <>
               <a href="https://www.linkedin.com/in/gbrlmngr/" target="_blank" rel="noopener noreferrer">
                  <h3>
                     Gabriel Mangiurea
                     <img src="./images/box-arrow-white.svg" alt="" />
                  </h3>
               </a>
            </>,
         ],
         job: "Level9.GG Founder, Senior Software Engineer",
         image: "GabrielMangiurea.jpg",
         testimonial:
            "I've had the great pleasure of meeting the 2Morrow IT Solutions team by interacting with one of their LinkedIn posts about the Budget Gamer mobile app (you should download it too). Our common interest and passion for gaming and technology lead us into forming a partnership between Budget Gamer and Level9.GG (our gaming community) which I am really proud of. The team not only demonstrated high expertise in mobile and web software development, but also a genuine passion for their craft, through rigorous and secure engineering practices, and, most importantly, a proactive and positive attitude. If you are serious about your business' success, you really should hire the team at 2Morrow IT Solutions!",
      },
      {
         name: [<h3>Goro Miyamoto</h3>],
         job: "Media Producer and Founder",
         image: "person-shape.svg",
         testimonial:
            "Their work is honest. They are skilled, of course, but the important thing in their work is to understand the people that are working with. They work with kindness. And they are tough enough to complete even the most difficult tasks. They exceed expectations. If you hire them, you can rest assured that you can trust them with your work. They will always be faithful to their clients.",
      },
   ];

   // const [counter, setCounter] = useState(0);

   const [review, setReview] = useState(testimonials[i]);
   let j = testimonials.length;

   async function prevTest() {
      i--;
      i = i < 0 ? j - 1 : i;
      setReview(testimonials[i]);
   }
   async function nextTest() {
      i++;
      i = i > j - 1 ? i - j : i;
      setReview(testimonials[i]);
   }
   return (
      <>
         <div className="testimonials">
            <h1>See what our previous clients think about us</h1>
            <div className="wrapper">
               {/* <div className="banner">
                  <h1>Testimonials</h1>
               </div> */}
               <div className="testimonial-container" id="testimonial-container">
                  <p>{review.testimonial}</p>
                  <img src={"./images/" + review.image} alt="" />
                  {review.name}
                  <h6>{review.job}</h6>
               </div>
               <button
                  id="prev"
                  onClick={() => {
                     prevTest();
                  }}
               >
                  <img src="./images/down-arrow.svg" alt="" />
               </button>
               <button
                  id="next"
                  onClick={() => {
                     nextTest();
                  }}
               >
                  <img src="./images/down-arrow.svg" alt="" />
               </button>
            </div>
         </div>
      </>
   );
};
export default Testimonials;
