import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import { db } from "../firebase/init";
import { collection, getDocs, orderBy, query, Timestamp, where } from "firebase/firestore";
import { estimateReadTime, formatTimestamp } from "../components/utils";
import { useNavigate, useParams } from "react-router-dom";
// import { NavLinka } from "../components/NavbarElements";

const ArticlesList = () => {
   const { tagId } = useParams();
   const artRef = query(collection(db, "Articles"), orderBy("start_date", "desc"), where("start_date", "<=", Timestamp.fromMillis(Date.now())));
   const [articles, setArticles] = useState(null);
   const [tags, setTags] = useState(null);
   const navigate = useNavigate();
   function getAllUniqueTags(arrayOfObjects) {
      // Use a Set to store unique tags
      const uniqueTags = new Set();

      // Iterate through each object in the array
      arrayOfObjects.forEach((obj) => {
         // Iterate through the tags array of each object
         obj.tags.forEach((tag) => {
            // Add each tag to the Set
            uniqueTags.add(tag);
         });
      });

      // Convert the Set back to an array
      return Array.from(uniqueTags);
   }
   useEffect(() => {
      const fetchArticles = async () => {
         let queryRef = artRef;
         if (tagId) queryRef = query(collection(db, "Articles"), orderBy("start_date", "desc"), where("tags", "array-contains", tagId));
         const artSnap = await getDocs(queryRef).then((snap) => {
            return snap.docs.map((artDoc) => {
               return { ...artDoc.data(), id: artDoc.id, read: estimateReadTime(artDoc.data()) };
            });
         });
         Promise.all(artSnap).then((art) => {
            // console.log(art);
            setArticles(art);
         });
         const tagSnap = await getDocs(artRef).then((snap) => {
            return snap.docs.map((artDoc) => {
               return { tags: [...artDoc.data().tags], id: artDoc.id };
            });
         });
         Promise.all(tagSnap).then((art) => setTags(getAllUniqueTags(art)));
      };

      fetchArticles();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [tagId]);

   return (
      <>
         <div className="main">
            <img className="artic1-img" src="/images/blob.svg" alt="" />
            <img className="artic2-img" src="/images/blob.svg" alt="" />
            <div className="headline-articles">
               <h1>Articles</h1>
               <p>Discover insights in our collection of articles, offering valuable perspectives on the latest trends and developments in the tech industry.</p>
            </div>
            <div className="article-list-container">
               <aside className="article-list-options">
                  <h3 className="header-topics">Featured Topics</h3>
                  <div className="topics">
                     {tags &&
                        tags.map((tag) => (
                           <span
                              key={tag}
                              className="articleTag"
                              style={tag === tagId ? { backgroundColor: "#0076fe" } : {}}
                              onClick={() => {
                                 tag === tagId ? navigate("/articles/") : navigate("/articles/" + tag);
                              }}
                           >
                              {tag}
                           </span>
                        ))}
                     {!tags && <img className="loadAny" src="/images/spinner.svg" alt="" />}
                  </div>
               </aside>
               <main className="article-list">
                  {articles &&
                     articles.map((art) => (
                        <div className="article" onClick={() => navigate("/article/" + art.id)}>
                           <div className="article-author">
                              <img src={art.author.photo} alt="" />
                              <h4>{art.author.name}</h4>
                              <span>·</span>
                              <span className="article-date">{formatTimestamp(art.start_date.seconds)}</span>
                           </div>
                           <div className="article-section">
                              <div className="article-content" style={!art.thumbnail_photo ? { width: "100%" } : {}}>
                                 <h2>{art.title}</h2>
                                 <p>{art.content.find((path) => path.type === "paragraph").text}</p>
                              </div>
                              {art.thumbnail_photo && (
                                 <div className="article-thumbnail">
                                    <img src={art.thumbnail_photo} alt="" />
                                 </div>
                              )}
                           </div>
                           <div className="article-footer">
                              <div className="article-tags">
                                 {art.tags.map((tag) => (
                                    <span key={tag} className="articleTag">
                                       {tag}
                                    </span>
                                 ))}
                              </div>
                              <span>·</span>
                              <span className="article-read-time">{art.read} min read</span>
                           </div>
                        </div>
                     ))}
                  {!articles && <img className="loadAny" src="/images/spinner.svg" alt="" />}
               </main>
            </div>
         </div>

         <Footer></Footer>
      </>
   );
};

export default ArticlesList;
