import React from "react";

const ShareAnchor = (props) => {
   if (props.href) {
      let imageSrc = "./images/" + props.type + ".svg";
      return (
         <a href={props.href} target="_blank" rel="noopener noreferrer">
            <img src={imageSrc} alt="" />
         </a>
      );
   } else {
      return <></>;
   }
};

export default ShareAnchor;
