import React from "react";
import Footer from "../components/Footer";
const Privacy = () => {
   return (
      <>
         <div className="main">
            <div className="terms">
               <h1>PRIVACY POLICY</h1>
               <br />
               <strong>Last updated July 01, 2023</strong>
               <br />
               <br />
               This privacy notice for 2Morrow IT Solutions SRL (<strong>'Company'</strong>, <strong>'we'</strong>, <strong>'us'</strong>, or <strong>'our'</strong>), describes how and why we might collect, store, use, and/or share (<strong>'process'</strong>) your information when you use our
               services (<strong>'Services'</strong>), such as when you:
               <br />
               <br />
               <ul>
                  <li>
                     Visit our website at <a href="http://www.2morrowit.com">www.2morrowit.com</a>, or any website of ours that links to this privacy notice
                  </li>
                  <li> Engage with us in other related ways, including any sales, marketing, or events</li>
               </ul>
               <br />
               <strong>Questions or concerns?</strong> Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please contact us at
               <a href="mailto:contact@2morrowit.com"> contact@2morrowit.com</a> .
               <br />
               <br />
               <br />
               <h2>SUMMARY OF KEY POINTS</h2>
               <br />
               <br />
               <strong>What personal information do we process?</strong> When you visit, use, or navigate our Services, we may process personal information depending on how you interact with 2Morrow IT Solutions SRL and the Services, the choices you make, and the products and features you use. Learn
               more about personal information you disclose to us.
               <br />
               <br />
               <strong>Do we process any sensitive personal information?</strong> We do not process sensitive personal information.
               <br />
               <br />
               <strong>Do we receive any information from third parties?</strong> We do not receive any information from third parties.
               <br />
               <br />
               <strong>How do we process your information?</strong> We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your
               consent. We process your information only when we have a valid legal reason to do so. Learn more about how we process your information.
               <br />
               <br />
               <strong>In what situations and with which parties do we share personal information?</strong> We may share information in specific situations and with specific third parties. Learn more about when and with whom we share your personal information.
               <br />
               <br />
               <strong>How do we keep your information safe?</strong> We have organisational and technical processes and procedures in place to protect your personal information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100%
               secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorised third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Learn more about how we keep your information safe.
               <br />
               <br />
               <strong>What are your rights?</strong> Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information. Learn more about your privacy rights.
               <br />
               <br />
               <strong>How do you exercise your rights?</strong> The easiest way to exercise your rights is by submitting a data subject access request, or by contacting us. We will consider and act upon any request in accordance with applicable data protection laws.
               <br />
               <br />
               <h2>1. WHAT INFORMATION DO WE COLLECT?</h2>
               <br />
               <h3>Personal information you disclose to us</h3>
               <br />
               <strong>In Short: </strong> We collect personal information that you provide to us.
               <br />
               <br />
               We collect personal information that you voluntarily provide to us when you express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.
               <br />
               <br />
               <strong>Personal Information Provided by You. </strong>The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the
               following:
               <br />
               <br />
               <ul>
                  <li>names</li>
                  <li> phone numbers</li>
                  <li> email addresses</li>
                  <li> job titles</li>
                  <li> company names</li>
               </ul>
               <br />
               <strong>Sensitive Information.</strong> We do not process sensitive information.
               <br />
               <br />
               All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.
               <br />
               <br />
               <h3>Information automatically collected</h3>
               <br />
               <br />
               <strong>In Short:</strong> Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our Services.
               <br />
               <br />
               We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device
               characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services, and other technical information. This information is primarily needed to maintain the security and operation of our Services,
               and for our internal analytics and reporting purposes.
               <br />
               <br />
               Like many businesses, we also collect information through cookies and similar technologies.
               <br />
               <br />
               The information we collect includes:
               <br /> <br />
               <ul>
                  <li>
                     Log and Usage Data. Log and usage data is service-related, diagnostic, usage, and performance information our servers automatically collect when you access or use our Services and which we record in log files. Depending on how you interact with us, this log data may include your
                     IP address, device information, browser type, and settings and information about your activity in the Services (such as the date/time stamps associated with your usage, pages and files viewed, searches, and other actions you take such as which features you use), device event
                     information (such as system activity, error reports (sometimes called 'crash dumps'), and hardware settings).
                  </li>
                  <li>
                     Device Data. We collect device data such as information about your computer, phone, tablet, or other device you use to access the Services. Depending on the device used, this device data may include information such as your IP address (or proxy server), device and application
                     identification numbers, location, browser type, hardware model, Internet service provider and/or mobile carrier, operating system, and system configuration information.
                  </li>
                  <li>
                     Location Data. We collect location data such as information about your device's location, which can be either precise or imprecise. How much information we collect depends on the type and settings of the device you use to access the Services. For example, we may use GPS and
                     other technologies to collect geolocation data that tells us your current location (based on your IP address). You can opt out of allowing us to collect this information either by refusing access to the information or by disabling your Location setting on your device. However,
                     if you choose to opt out, you may not be able to use certain aspects of the Services.
                  </li>
               </ul>
               <br />
               <br />
               <h2>2. HOW DO WE PROCESS YOUR INFORMATION?</h2>
               <br />
               <br />
               <strong>In Short:</strong> We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.
               <br />
               <br />
               <strong>We process your personal information for a variety of reasons, depending on how you interact with our Services, including:</strong> <br /> <br />
               <ul>
                  <li>
                     <strong>To deliver and facilitate delivery of services to the user.</strong> We may process your information to provide you with the requested service.
                  </li>
                  <li>
                     <strong>To respond to user inquiries/offer support to users.</strong> We may process your information to respond to your inquiries and solve any potential issues you might have with the requested service.
                  </li>
                  <li>
                     <strong>To save or protect an individual's vital interest.</strong> We may process your information when necessary to save or protect an individual’s vital interest, such as to prevent harm.
                  </li>
               </ul>
               <br />
               <br />
               <h2>3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?</h2>
               <br />
               <br />
               <strong>In Short:</strong> We only process your personal information when we believe it is necessary and we have a valid legal reason (i.e. legal basis) to do so under applicable law, like with your consent, to comply with laws, to provide you with services to enter into or fulfil our
               contractual obligations, to protect your rights, or to fulfil our legitimate business interests.
               <br />
               <br />
               <strong>If you are located in the EU or UK, this section applies to you.</strong>
               <br />
               <br />
               The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal bases we rely on in order to process your personal information. As such, we may rely on the following legal bases to process your personal information:
               <br /> <br />
               <ul>
                  <li>
                     {" "}
                     <strong>Consent.</strong>  We may process your information if you have given us permission (i.e. consent) to use your personal information for a specific purpose. You can withdraw your consent at any time. Learn more about withdrawing your consent.
                  </li>
                  <li>
                     {" "}
                     <strong>Performance of a Contract.</strong> We may process your personal information when we believe it is necessary to fulfil our contractual obligations to you, including providing our Services or at your request prior to entering into a contract with you.
                  </li>
                  <li>
                     <strong>Legal Obligations.</strong> We may process your information where we believe it is necessary for compliance with our legal obligations, such as to cooperate with a law enforcement body or regulatory agency, exercise or defend our legal rights, or disclose your
                     information as evidence in litigation in which we are involved.
                  </li>
                  <li>
                     {" "}
                     <strong>Vital Interests.</strong> We may process your information where we believe it is necessary to protect your vital interests or the vital interests of a third party, such as situations involving potential threats to the safety of any person.
                  </li>
               </ul>
               <br />
               <br />
               <strong>If you are located in Canada, this section applies to you.</strong>
               <br />
               <br />
               We may process your information if you have given us specific permission (i.e. express consent) to use your personal information for a specific purpose, or in situations where your permission can be inferred (i.e. implied consent). You can withdraw your consent at any time.
               <br />
               <br />
               In some exceptional cases, we may be legally permitted under applicable law to process your information without your consent, including, for example:
               <br />
               <br />
               <ul>
                  <li> If collection is clearly in the interests of an individual and consent cannot be obtained in a timely way</li>
                  <li> For investigations and fraud detection and prevention</li>
                  <li> For business transactions provided certain conditions are met</li>
                  <li> If it is contained in a witness statement and the collection is necessary to assess, process, or settle an insurance claim</li>
                  <li> For identifying injured, ill, or deceased persons and communicating with next of kin</li>
                  <li> If we have reasonable grounds to believe an individual has been, is, or may be victim of financial abuse</li>
                  <li>
                     If it is reasonable to expect collection and use with consent would compromise the availability or the accuracy of the information and the collection is reasonable for purposes related to investigating a breach of an agreement or a contravention of the laws of Canada or a
                     province
                  </li>
                  <li> If disclosure is required to comply with a subpoena, warrant, court order, or rules of the court relating to the production of records</li>
                  <li> If it was produced by an individual in the course of their employment, business, or profession and the collection is consistent with the purposes for which the information was produced</li>
                  <li> If the collection is solely for journalistic, artistic, or literary purposes</li>
                  <li> If the information is publicly available and is specified by the regulations</li>
               </ul>
               <br />
               <br />
               <h2>4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h2>
               <br />
               <br />
               <strong> In Short:</strong> We may share information in specific situations described in this section and/or with the following third parties.
               <br />
               <br />
               We may need to share your personal information in the following situations:
               <br /> <br />
               <ul>
                  <li>
                     <strong>Business Transfers. </strong>We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.
                  </li>
                  <li>
                     <strong>Affiliates.</strong> We may share your information with our affiliates, in which case we will require those affiliates to honour this privacy notice. Affiliates include our parent company and any subsidiaries, joint venture partners, or other companies that we control or
                     that are under common control with us.
                  </li>
               </ul>
               <br />
               <br />
               <h2>5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h2>
               <br />
               <br />
               <strong>In Short:</strong> We may use cookies and other tracking technologies to collect and store your information.
               <br />
               <br />
               We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice.
               <br />
               <br />
               <h2>6. HOW LONG DO WE KEEP YOUR INFORMATION?</h2>
               <br />
               <br />
               <strong>In Short: </strong>We keep your information for as long as necessary to fulfil the purposes outlined in this privacy notice unless otherwise required by law.
               <br />
               <br />
               We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements).
               <br />
               <br />
               When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymise such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your
               personal information and isolate it from any further processing until deletion is possible.
               <br />
               <br />
               <h2>7. HOW DO WE KEEP YOUR INFORMATION SAFE?</h2>
               <br />
               <br />
               <strong>In Short: </strong>We aim to protect your personal information through a system of organisational and technical security measures.
               <br />
               <br />
               We have implemented appropriate and reasonable technical and organisational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet
               or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorised third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information.
               Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.
               <br />
               <br />
               <h2>8. WHAT ARE YOUR PRIVACY RIGHTS?</h2>
               <br />
               <br />
               <strong>In Short:</strong> In some regions, such as the European Economic Area (EEA), United Kingdom (UK), and Canada, you have rights that allow you greater access to and control over your personal information. You may review, change, or terminate your account at any time.
               <br />
               <br />
               In some regions (like the EEA, UK, and Canada), you have certain rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing
               of your personal information; and (iv) if applicable, to data portability. In certain circumstances, you may also have the right to object to the processing of your personal information. You can make such a request by contacting us by using the contact details provided in the section
               'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?' below.
               <br />
               <br />
               We will consider and act upon any request in accordance with applicable data protection laws.
               <br /> <br />
               If you are located in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to your Member State data protection authority or UK data protection authority.
               <br />
               <br />
               If you are located in Switzerland, you may contact the Federal Data Protection and Information Commissioner.
               <br />
               <br />
               <strong>Withdrawing your consent:</strong> If we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent at any
               time by contacting us by using the contact details provided in the section 'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?' below.
               <br />
               <br />
               However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.
               <br />
               <br />
               <strong>Cookies and similar technologies:</strong> Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain
               features or services of our Services. You may also opt out of interest-based advertising by advertisers on our Services.
               <br />
               <br />
               If you have questions or comments about your privacy rights, you may email us at <a href="mailto:contact@2morrowit.com">contact@2morrowit.com</a>.
               <br />
               <br />
               <h2>9. CONTROLS FOR DO-NOT-TRACK FEATURES</h2>
               <br />
               <br />
               Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ('DNT') feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform
               technology standard for recognising and implementing DNT signals has been finalised. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted
               that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.
               <br />
               <br />
               <h2>10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h2>
               <br />
               <p>The California Consumer Privacy Act (CCPA) requires us to disclose categories of Personal Information we collect and how we use it, the categories of sources from whom we collect Personal Information, and the third parties with whom we share it, which we have explained above.</p>
               <br />
               <p>We are also required to communicate information about rights California residents have under California law. You may exercise the following rights:</p>
               <br />
               <ul>
                  <li>
                     Right to Know and Access. You may submit a verifiable request for information regarding the: (1) categories of Personal Information we collect, use, or share; (2) purposes for which categories of Personal Information are collected or used by us; (3) categories of sources from
                     which we collect Personal Information; and (4) specific pieces of Personal Information we have collected about you.
                  </li>
                  <li>Right to Equal Service. We will not discriminate against you if you exercise your privacy rights.</li>
                  <li>Right to Delete. You may submit a verifiable request to close your account and we will delete Personal Information about you that we have collected.</li>
                  <li>Request that a business that sells a consumer's personal data, not sell the consumer's personal data.</li>
               </ul>
               <br />
               <p>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.</p>
               <br />
               <h3>California Online Privacy Protection Act (CalOPPA)</h3>
               <br />
               <p>CalOPPA requires us to disclose categories of Personal Information we collect and how we use it, the categories of sources from whom we collect Personal Information, and the third parties with whom we share it, which we have explained above.</p>
               <br />
               <p>CalOPPA users have the following rights:</p>
               <br />
               <ul>
                  <li>
                     Right to Know and Access. You may submit a verifiable request for information regarding the: (1) categories of Personal Information we collect, use, or share; (2) purposes for which categories of Personal Information are collected or used by us; (3) categories of sources from
                     which we collect Personal Information; and (4) specific pieces of Personal Information we have collected about you.
                  </li>
                  <li>Right to Equal Service. We will not discriminate against you if you exercise your privacy rights.</li>
                  <li>Right to Delete. You may submit a verifiable request to close your account and we will delete Personal Information about you that we have collected.</li>
                  <li>Right to request that a business that sells a consumer's personal data, not sell the consumer's personal data.</li>
               </ul>
               <br />
               <p>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.</p>
               <br />
               <p>We do not sell the Personal Information of our users.</p>
               <br />
               <h2>11. DO VIRGINIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h2>
               <br />
               <strong> In Short:</strong> Yes, if you are a resident of Virginia, you may be granted specific rights regarding access to and use of your personal information.
               <br />
               <br />
               <h3> Virginia CDPA Privacy Notice</h3>
               <br />
               Under the Virginia Consumer Data Protection Act (CDPA):
               <br />
               <br />
               'Consumer' means a natural person who is a resident of the Commonwealth acting only in an individual or household context. It does not include a natural person acting in a commercial or employment context.
               <br />
               <br />
               'Personal data' means any information that is linked or reasonably linkable to an identified or identifiable natural person. 'Personal data' does not include de-identified data or publicly available information.
               <br />
               <br />
               'Sale of personal data' means the exchange of personal data for monetary consideration.
               <br />
               <br />
               If this definition 'consumer' applies to you, we must adhere to certain rights and obligations regarding your personal data.
               <br />
               <br />
               The information we collect, use, and disclose about you will vary depending on how you interact with 2Morrow IT Solutions SRL and our Services. To find out more, please visit the following links:
               <br />
               <br />
               <ul>
                  <li> Personal data we collect</li>
                  <li> How we use your personal data</li>
                  <li> When and with whom we share your personal data</li>
               </ul>
               <br />
               Your rights with respect to your personal data
               <br />
               <br />
               <ul>
                  <li> Right to be informed whether or not we are processing your personal data</li>
                  <li> Right to access your personal data</li>
                  <li> Right to correct inaccuracies in your personal data</li>
                  <li> Right to request deletion of your personal data</li>
                  <li> Right to obtain a copy of the personal data you previously shared with us</li>
                  <li> Right to opt out of the processing of your personal data if it is used for targeted advertising, the sale of personal data, or profiling in furtherance of decisions that produce legal or similarly significant effects ('profiling')</li>
               </ul>
               <br />
               <br />
               2Morrow IT Solutions SRL has not sold any personal data to third parties for business or commercial purposes. 2Morrow IT Solutions SRL will not sell personal data in the future belonging to website visitors, users, and other consumers.
               <br />
               <br />
               <strong>Exercise your rights provided under the Virginia CDPA</strong>
               <br />
               <br />
               More information about our data collection and sharing practices can be found in this privacy notice.
               <br />
               <br />
               You may contact us by email at <a href="mailto:contact@2morrowit.com">contact@2morrowit.com</a>, by submitting a data subject access request, or by referring to the contact details at the bottom of this document.
               <br />
               <br />
               If you are using an authorised agent to exercise your rights, we may deny a request if the authorised agent does not submit proof that they have been validly authorised to act on your behalf.
               <br />
               <br />
               <strong>Verification process</strong>
               <br />
               <br />
               We may request that you provide additional information reasonably necessary to verify you and your consumer's request. If you submit the request through an authorised agent, we may need to collect additional information to verify your identity before processing your request.
               <br />
               <br />
               Upon receiving your request, we will respond without undue delay, but in all cases, within forty-five (45) days of receipt. The response period may be extended once by forty-five (45) additional days when reasonably necessary. We will inform you of any such extension within the
               initial 45-day response period, together with the reason for the extension.
               <br />
               <br />
               <strong>Right to appeal</strong>
               <br />
               <br />
               If we decline to take action regarding your request, we will inform you of our decision and reasoning behind it. If you wish to appeal our decision, please email us at <a href="mailto:contact@2morrowit.com">contact@2morrowit.com</a>. Within sixty (60) days of receipt of an appeal, we
               will inform you in writing of any action taken or not taken in response to the appeal, including a written explanation of the reasons for the decisions. If your appeal if denied, you may contact the Attorney General to submit a complaint.
               <br />
               <br />
               <h2>12. DO WE MAKE UPDATES TO THIS NOTICE?</h2>
               <br />
               <strong>In Short: </strong>Yes, we will update this notice as necessary to stay compliant with relevant laws.
               <br />
               <br />
               We may update this privacy notice from time to time. The updated version will be indicated by an updated 'Revised' date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently
               posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.
               <br />
               <br />
               <h2>13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h2>
               <br />
               If you have questions or comments about this notice, contact use at:
               <br />
               <br />
               <strong> 2Morrow IT Solutions SRL</strong>
               <br />
               <strong>Address:</strong> str. OLTULUI Nr. 25 et. 3 ap. 31,
               <br />
               Galati, Galati 800444, Romania
               <br />
               <strong> Phone:</strong>
               <a href="tel:+40790545051"> +40 790 545 051</a>
               <br />
               <strong>Email:</strong>
               <a href="mailto:contact@2morrowit.com">contact@2morrowit.com</a>
               <br />
               <br />
               <h2>14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</h2>
               <br />
               You have the right to request access to the personal information we collect from you, change that information, or delete it. To request to review, update, or delete your personal information, please fill out and submit a data subject access request via email.
               <br />
            </div>
         </div>
         <Footer></Footer>
      </>
   );
};

export default Privacy;
