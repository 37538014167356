// import "./store.css";
import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { NavStore } from "./nav";
import HomeB2B from "./home";
import UpdatesB2B from "./updates";
import { browserLocalPersistence, onAuthStateChanged, setPersistence, signInWithEmailAndPassword } from "firebase/auth";
import { db, auth } from "../../firebase/initStore";
import SettingsB2B from "./settings";

function StoreApp() {
   // eslint-disable-next-line no-unused-vars
   const [user, setUser] = useState(null);
   async function loginEvent() {
      await signInWithEmailAndPassword(auth, "b2btest@2morrowit.com", "Qwerty123!").then(async (userCredential) => {
         const userNew = userCredential.user;
         console.log(userNew);
         setUser(userNew);
      });
   }
   useEffect(() => {
      setPersistence(auth, browserLocalPersistence).then(() => {
         const unsubscribeAuthStateChanged = onAuthStateChanged(auth, async (currentUser) => {
            if (currentUser) {
               console.log(currentUser);
               setUser(currentUser);
            } else {
               loginEvent();
            }
         });
         return () => unsubscribeAuthStateChanged();
      });
      /* eslint-disable-next-line */
   }, [db, auth]);
   useEffect(() => {
      //   loginEvent();
   }, []);
   return (
      <div className="mainStoreApp">
         <Routes>
            <Route path="/" element={<HomeB2B />} />
            <Route path="/updates" element={<UpdatesB2B />} />
            <Route path="/settings" element={<SettingsB2B />} />
         </Routes>
         <NavStore />
      </div>
   );
}

export default StoreApp;
