import { collection, getDocs, limit, orderBy, query } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db, storage } from "../../firebase/initStore";
import { getDownloadURL, ref } from "firebase/storage";
import OpenAppDetails from "./openDetails";
function formatTimestamp(timestampInSeconds) {
   const date = new Date(timestampInSeconds * 1000); // Convert seconds to milliseconds
   const day = String(date.getDate()).padStart(2, "0"); // Get day with leading zero if needed
   const month = new Intl.DateTimeFormat("en", { month: "short" }).format(date); // Get abbreviated month name
   const year = date.getFullYear(); // Get full year
   return `${day} ${month} ${year}`;
}
function formatTimestampLong(timestamp) {
   const date = new Date(timestamp * 1000);
   const day = String(date.getDate()).padStart(2, "0");
   const month = String(date.getMonth() + 1).padStart(2, "0");
   const year = date.getFullYear();
   const hours = String(date.getHours()).padStart(2, "0");
   const minutes = String(date.getMinutes()).padStart(2, "0");
   return `${day}.${month}.${year} at ${hours}:${minutes}`;
}
const AppDetails = ({ app, close }) => {
   const [ss, setSS] = useState(null);
   const [update, setUpdate] = useState(null);
   const [openDetails, setOpenDetails] = useState(null);

   useEffect(() => {
      if (app) {
         const fetchData = app.materials.map(async (img) => {
            let iconRef = ref(storage, img);
            const url = await getDownloadURL(iconRef);
            // eslint-disable-next-line no-unused-vars
            const response = await fetch(url, { mode: "no-cors", redirect: "follow" });
            return { url };
         });

         Promise.all(fetchData).then(async (newData) => {
            console.log(newData);
            setSS(newData);
         });
         const updateQuery = query(collection(db, "Customers", "J8CVSPO3yrNzUIqt0y8I", "Apps", app.id, "android"), orderBy("releasedOn", "desc"), limit(1));
         getDocs(updateQuery)
            .then((querySnapshot) => {
               // Check if there are any documents returned by the query
               if (!querySnapshot.empty) {
                  // Get the data from the first document
                  const newData = querySnapshot.docs[0].data();
                  console.log(newData);
                  setUpdate(newData);
               } else {
                  console.log("No documents found.");
               }
            })
            .catch((error) => {
               console.error("Error fetching documents: ", error);
            });
      }
   }, [app]);
   console.log(app);
   return (
      <>
         <div className={app ? (openDetails ? "appDetailsPage activeDetails" : "appDetailsPage active") : "appDetailsPage"}>
            <div className="header">
               <div className="backBtn">
                  <button
                     onClick={() => {
                        close();
                     }}
                  >
                     <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                        <path d="M647-440H160v-80h487L423-744l57-56 320 320-320 320-57-56 224-224Z" />
                     </svg>
                  </button>
               </div>
               <h2>App Details</h2>
               <div className="settings">
                  <button>
                     <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                        <path d="m370-80-16-128q-13-5-24.5-12T307-235l-119 50L78-375l103-78q-1-7-1-13.5v-27q0-6.5 1-13.5L78-585l110-190 119 50q11-8 23-15t24-12l16-128h220l16 128q13 5 24.5 12t22.5 15l119-50 110 190-103 78q1 7 1 13.5v27q0 6.5-2 13.5l103 78-110 190-118-50q-11 8-23 15t-24 12L590-80H370Zm112-260q58 0 99-41t41-99q0-58-41-99t-99-41q-59 0-99.5 41T342-480q0 58 40.5 99t99.5 41Z" />
                     </svg>
                  </button>
               </div>
            </div>
            {app && (
               <div className="app">
                  <div className="appHeader">
                     <div className="applogo">
                        <img src={app.icon} alt="" />
                     </div>
                     <div className="appDetails">
                        <h2>{app.name}</h2>
                        <p>{app.shortDescription}</p>
                     </div>
                  </div>
                  <a href="https://firebasestorage.googleapis.com/v0/b/morrow-it-solutions-web.appspot.com/o/2Morrow%20Store%20B2B.apk?alt=media&token=f63dbcd7-1b25-4bdc-a039-af84f431d1ac" download={"2Morrow Store B2B"} className="installButton" style={{ textDecoration: "none" }}>
                     Download Store APK
                  </a>
                  <div className="screenshots">{ss && ss.map((img) => <img src={img.url} alt="" />)}</div>
                  <div className="aboutApp">
                     <div className="descHeader">
                        <h3>About this app</h3>
                        <button
                           onClick={() => {
                              console.log("aici");
                              setOpenDetails({ title: "About this app", desc: app.description });
                           }}
                        >
                           <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                              <path d="M647-440H160v-80h487L423-744l57-56 320 320-320 320-57-56 224-224Z" />
                           </svg>
                        </button>
                     </div>
                     <p>{app.description}</p>
                  </div>

                  {update && (
                     <>
                        {update.versionDescription && (
                           <div className="aboutApp">
                              <div className="descHeader">
                                 <h3>What's new</h3>
                                 <button onClick={() => setOpenDetails({ title: "What's new", desc: update.versionDescription })}>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                                       <path d="M647-440H160v-80h487L423-744l57-56 320 320-320 320-57-56 224-224Z" />
                                    </svg>
                                 </button>
                              </div>
                              <p>{update.versionDescription}</p>
                           </div>
                        )}

                        <div className="aboutApp">
                           <div className="descHeader">
                              <h3>App Info</h3>
                           </div>
                           <div className="infoList">
                              <div>
                                 <span>Version</span>
                                 <span>{update.versionName}</span>
                              </div>
                              <div>
                                 <span>Updated on</span>
                                 <span>{formatTimestampLong(update.releasedOn.seconds)}</span>
                              </div>
                              <div>
                                 <span>Download size</span>
                                 <span>{update.downloadSize + " MB"}</span>
                              </div>
                              <div>
                                 <span>Required OS</span>
                                 <span>{update.minSdk}</span>
                              </div>
                              <div>
                                 <span>Released on</span>
                                 <span>{formatTimestamp(app.releasedOn.seconds)}</span>
                              </div>
                           </div>
                        </div>
                     </>
                  )}
               </div>
            )}
         </div>
         <OpenAppDetails details={openDetails} close={() => setOpenDetails(null)} />
      </>
   );
};

export default AppDetails;
