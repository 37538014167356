// import { FaBars } from "react-icons/fa";
import React from "react";
import { NavLink as Link, useLocation } from "react-router-dom";
import styled from "styled-components";

export const NavLink = styled(Link)`
   color: #4b4e56; //#dff4ff;
   display: flex;
   flex-direction: column;
   gap: 2px;
   font-size: 10px;
   letter-spacing: 0px;
   font-weight: 700;
   align-items: center;
   justify-content: center;
   text-decoration: none;
   padding: 0 1rem;
   height: 100%;
   cursor: pointer;
   text-align: center;
   .navimg {
      height: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 2px 16px;
      border-radius: 32px;
      position: relative;
      overflow: hidden;
      svg {
         fill: #44474f;
         height: 18px;
         width: 18px;
      }
      transition: all ease-in 0.4s;
   }
   transition: all ease-in 0.3s;
   span {
      height: 12px;
   }
   &.active {
      color: #000000;
      font-weight: 700;
      // background-color: #006a8b; //rgb(0, 160, 208, 0.4);
      .navimg {
         background-color: #dbe2f9;
         svg {
            fill: #141b2c;
         }
      }
   }
   @media (prefers-color-scheme: dark) {
      color: #c5c6d0;
      .navimg {
         svg {
            fill: #c5c6d0;
         }
      }
      &.active {
         color: #e2e2e9;
         .navimg {
            background-color: #3f4759;
            svg {
               fill: #dbe2f9;
            }
         }
      }
   }
`;
const NavMenu = () => {
   return (
      <>
         <div className={`navMenuStore`}>
            <NavLink
               to="/StoreB2B/"
               onClick={() => {
                  window.scroll(0, 0);
               }}
            >
               <div className="navimg">
                  <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
                     <path d="M0 0h24v24H0V0z" fill="none" />
                     <path d="M10 19v-5h4v5c0 .55.45 1 1 1h3c.55 0 1-.45 1-1v-7h1.7c.46 0 .68-.57.33-.87L12.67 3.6c-.38-.34-.96-.34-1.34 0l-8.36 7.53c-.34.3-.13.87.33.87H5v7c0 .55.45 1 1 1h3c.55 0 1-.45 1-1z" />
                  </svg>
               </div>
               <span>Home</span>
            </NavLink>
            <NavLink
               to="/StoreB2B/updates"
               onClick={() => {
                  window.scroll(0, 0);
               }}
            >
               <div className="navimg">
                  <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                     <path d="M480-120q-75 0-140.5-28.5t-114-77q-48.5-48.5-77-114T120-480q0-75 28.5-140.5t77-114q48.5-48.5 114-77T480-840q82 0 155.5 35T760-706v-94h80v240H600v-80h110q-41-56-101-88t-129-32q-117 0-198.5 81.5T200-480q0 117 81.5 198.5T480-200q105 0 183.5-68T756-440h82q-15 137-117.5 228.5T480-120Zm112-192L440-464v-216h80v184l128 128-56 56Z" />
                  </svg>
               </div>
               <span>Updates</span>
            </NavLink>
            <NavLink
               to="/StoreB2B/settings"
               onClick={() => {
                  window.scroll(0, 0);
               }}
            >
               <div className="navimg">
                  <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                     <path d="m370-80-16-128q-13-5-24.5-12T307-235l-119 50L78-375l103-78q-1-7-1-13.5v-27q0-6.5 1-13.5L78-585l110-190 119 50q11-8 23-15t24-12l16-128h220l16 128q13 5 24.5 12t22.5 15l119-50 110 190-103 78q1 7 1 13.5v27q0 6.5-2 13.5l103 78-110 190-118-50q-11 8-23 15t-24 12L590-80H370Zm112-260q58 0 99-41t41-99q0-58-41-99t-99-41q-59 0-99.5 41T342-480q0 58 40.5 99t99.5 41Z" />
                  </svg>
               </div>
               <span>Settings</span>
            </NavLink>
         </div>
      </>
   );
};

export const NavStore = () => {
   const location = useLocation();

   // Define routes where you want to hide the Nav component
   const hiddenRoutes = ["/download"];

   // Return null if the current route matches a hidden route
   if (hiddenRoutes.includes(location.pathname)) {
      return null;
   }
   window.addEventListener("scroll", function () {
      if (window.scrollY > 30) {
         if (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) {
            document.querySelector("nav").style.backgroundColor = "rgb(19 21 23 / 96%)"; //"#e0f4ff";
         } else {
            document.querySelector("nav").style.backgroundColor = "rgb(224 244 255 / 95%)"; //"#e0f4ff";
         }
      } else {
         //remove the background property so it comes transparent again (defined in your css)
         document.querySelector("nav").style.backgroundColor = "transparent"; //"#f3faff";
      }
   });
   return (
      <div className="NavStore">
         <NavMenu></NavMenu>
      </div>
   );
};
