import React, { useEffect, useRef, useState } from "react";
import { auth, db, storage } from "../firebase/init";
import { Timestamp, doc, serverTimestamp, setDoc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import { uploadFileAndGetURL } from "../components/utils";

function dateStringToTimestamp(dateString) {
   // Parse the date string to create a Date object
   const date = new Date(dateString);

   // Get the timestamp in milliseconds
   const timestamp = date.getTime();

   return timestamp;
}
function getTextareaRowsToArray(textarea) {
   const rowsArray = textarea.split("\n");
   const filteredArray = rowsArray.filter((row) => row.trim() !== "");
   return filteredArray;
}
const langList = [
   "bash",
   "c",
   "clojure",
   "cpp",
   "csharp",
   "dart",
   "elixir",
   "elm",
   "erlang",
   "fsharp",
   "graphql",
   "go",
   "groovy",
   "haskell",
   "html",
   "java",
   "javascript",
   "jsx",
   "julia",
   "kotlin",
   "lisp",
   "makefile",
   "matlab",
   "objectivec",
   "ocaml",
   "php",
   "python",
   "r",
   "ruby",
   "rust",
   "scala",
   "sql",
   "swift",
   "tsx",
   "typescript",
];

const AddArticle = () => {
   const [fields, setFields] = useState([{ type: "heading" }]);
   const [user, setUser] = useState(false);
   const [thumbnail, setThumbnail] = useState(null);

   const titleRef = useRef(null);
   const articleIdRef = useRef(null);
   const startDateRef = useRef(null);
   const authorRef = useRef(null);
   const tagsRef = useRef(null);

   useEffect(() => {
      onAuthStateChanged(auth, async (currentUser) => {
         if (currentUser) {
            setUser(true);
         } else {
            setUser(false);
         }
      });
   });

   const uploadThumbnail = async (id) => {
      if (!thumbnail) return null; // Check if thumbnail exists
      if (typeof thumbnail === "string") return thumbnail;
      const imageURL = await uploadFileAndGetURL(thumbnail, `Articles/${id}/thumbnail`, storage);
      return imageURL;
   };

   const uploadImage = async (imageFile, articleId) => {
      const imageURL = await uploadFileAndGetURL(imageFile, `Articles/${articleId}/${imageFile.name}`, storage);
      return imageURL;
   };
   const handleSubmit = async () => {
      let title = titleRef.current.value;
      let id = articleIdRef.current.value;
      let startDate = dateStringToTimestamp(startDateRef.current.value);
      let content = fields;
      let author;
      switch (authorRef.current.value) {
         case "alex_cehan":
            author = {
               name: "Alexandru Cehan",
               photo: "/images/alex_cehan_cv_small.jpg",
            };
            break;
         case "dragos_ciot":
            author = {
               name: "Dragos Georgian Ciot",
               photo: "/images/dragos_ciot_cv_small.jpg",
            };
            break;
         case "alex_tiron":
            author = {
               name: "Alexandru Bogdan Tiron",
               photo: "/images/alex_tiron_cv_small.jpg",
            };
            break;
         case "alex_costin":
            author = {
               name: "Alexandru Costin",
               photo: "/images/alex_costin_cv_small.jpg",
            };
            break;
         case "alex_cretu":
            author = {
               name: "Alexandru Cretu",
               photo: "/images/alex_cretu_cv_small.jpg",
            };
            break;
         default:
            author = {
               name: "Alexandru Cehan",
               photo: "/images/alex_cehan_cv_small.jpg",
            };
            break;
      }
      let tags = tagsRef.current.value.split(", ");

      const thumbnailURL = await uploadThumbnail(id);

      if (!title || !id || !startDate || !author || !tags || !thumbnailURL || content.length <= 1 || tags.length === 0) {
         alert("Please fill in all required fields.");
         return;
      }
      // Upload all image fields and update their URLs in fields
      await Promise.all(
         content.map(async (field) => {
            if (field.type === "image") {
               console.log(field);
               console.log(field.path);
               console.log(typeof field.path);
               if (typeof field.path === "object") {
                  const imageURL = await uploadImage(field.path, id);
                  field.path = imageURL;
               }
            } else if (field.type === "ordered_list" || field.type === "unordered_list") {
               field.text = field.text.filter((item) => item !== "");
            }
         })
      );

      // Construct the article object
      const article = {
         title: title,
         start_date: Timestamp.fromMillis(startDate),
         created_at: serverTimestamp(),
         author: author,
         tags: tags,
         thumbnail_photo: thumbnailURL,
         content: content,
      };
      console.log(article);
      // Set the article document in Firestore
      const articleRef = doc(db, "Articles", id);
      await setDoc(articleRef, article)
         .then(() => {
            alert("Article successfully submitted:", article);
            setFields([{ type: "heading" }]);
            setThumbnail(null);
            titleRef.current.value = "";
            articleIdRef.current.value = "";
            startDateRef.current.value = undefined;
            tagsRef.current.value = "";
         })
         .catch((error) => {
            console.log("Fail:", error);
            alert("Fail:", error);
         });

      console.log("Article successfully submitted:", article);
   };
   useEffect(() => {
      console.log(fields);
   }, [fields]);
   return (
      <>
         {user && (
            <div className="main">
               <img className="articDet1-img" src="/images/blob.svg" alt="" />
               <img className="articDet2-img" src="/images/blob.svg" alt="" />
               <img className="articDet3-img" src="/images/blob.svg" alt="" />
               <div className="addArticle-container">
                  <h1>Add Article</h1>
                  <div className="article-field">
                     <label htmlFor="article-title" className="article-text">
                        Title:
                        <input type="text" name="article-title" id="article-title" ref={titleRef} />
                     </label>
                     <label htmlFor="article-id" className="article-text">
                        Article id:
                        <input type="text" name="article-id" id="article-id" ref={articleIdRef} />
                     </label>
                     <label htmlFor="article-start-date">
                        Start Date:
                        <input type="datetime-local" name="article-start-date" id="article-start-date" ref={startDateRef} />
                     </label>
                     <label htmlFor="article-author">
                        Author:
                        <select name="article-author" id="article-author" ref={authorRef}>
                           <option value="alex_cehan">Alexandru Cehan</option>
                           <option value="dragos_ciot">Dragos Georgian Ciot</option>
                           <option value="alex_tiron">Alexandru Bogdan Tiron</option>
                           <option value="alex_costin">Alexandru Costin</option>
                           <option value="alex_cretu">Alexandru Cretu</option>
                        </select>
                     </label>
                     <label htmlFor="thumbnail_photo" className="article-text">
                        Thumbnail Photo:
                        <input
                           type="text"
                           name="thumbnail_link"
                           id="thumbnail_link"
                           // ref={thumbnailRef}
                           onChange={(e) => {
                              e.preventDefault();
                              setThumbnail(e.target.value);
                              document.getElementById("thumbnail_photo").value = "";
                           }}
                        />
                        <input
                           type="file"
                           name="thumbnail_photo"
                           id="thumbnail_photo"
                           // ref={thumbnailRef}
                           multiple={false}
                           accept=".svg,.jpg,.png,.jpeg"
                           onChange={(e) => {
                              e.preventDefault();
                              const file = e.target.files[0];
                              setThumbnail(file);
                              document.getElementById("thumbnail_link").value = "";
                           }}
                        />
                     </label>
                     <label htmlFor="article-tags" className="article-text">
                        Tags:
                        <input type="text" name="article-tags" id="article-tags" ref={tagsRef} placeholder="ex: Business, iOS, Android, Security ....." />
                     </label>
                  </div>
                  <h2>Add Content</h2>
                  {fields.map((field, index) => (
                     <div className="article-field" key={"article-field_" + index}>
                        <button
                           className="deleteField"
                           onClick={(e) => {
                              e.preventDefault();
                              setFields(fields.filter((item, indexItem) => indexItem !== index));
                           }}
                        >
                           Delete
                        </button>
                        <label htmlFor={"field_type_" + index}>
                           Type:
                           <select
                              value={field.type}
                              name={"field_type_" + index}
                              id={"field_type_" + index}
                              onChange={(e) => {
                                 const newFields = [...fields];
                                 if (e.target.value === "ordered_list" || e.target.value === "unordered_list") {
                                    newFields[index] = { type: e.target.value, text: [""] };
                                 } else if (e.target.value === "heading") {
                                    newFields[index] = { type: e.target.value, text: "", level: 1 };
                                 } else if (e.target.value === "code") {
                                    newFields[index] = { type: e.target.value, text: [""], lang: "javascript" };
                                 } else {
                                    newFields[index] = { type: e.target.value };
                                 }
                                 setFields(newFields);
                              }}
                           >
                              <option value="heading">Heading</option>
                              <option value="paragraph">Paragraph</option>
                              <option value="code">Code</option>
                              <option value="ordered_list">Ordered List</option>
                              <option value="unordered_list">Unordered List</option>
                              <option value="image">Image</option>
                           </select>
                        </label>

                        {field.type && field.type === "paragraph" && (
                           <label htmlFor={"field_paragraph_" + index} className="article-text">
                              Text:
                              <textarea
                                 name={"field_paragraph_" + index}
                                 id={"field_paragraph_" + index}
                                 onChange={(e) => {
                                    const newFields = [...fields];
                                    newFields[index] = { ...newFields[index], text: e.target.value };
                                    setFields(newFields);
                                 }}
                              />
                           </label>
                        )}
                        {field.type && (field.type === "ordered_list" || field.type === "unordered_list") && (
                           <label htmlFor={"field_list_" + index} className="article-text">
                              Text:
                              {field.text &&
                                 field.text.map((listItem, indexLi) => {
                                    return (
                                       <input
                                          defaultValue={listItem}
                                          type="text"
                                          name={"field_list_item_" + indexLi}
                                          id={"field_list_item_" + indexLi}
                                          onChange={(e) => {
                                             const newFields = [...fields];
                                             let newList = newFields[index].text;
                                             newList[indexLi] = e.target.value;
                                             newFields[index] = { ...newFields[index], text: newList };
                                             setFields(newFields);
                                          }}
                                       />
                                    );
                                 })}
                              <button
                                 className="add-list-item"
                                 onClick={() => {
                                    const newFields = [...fields];
                                    let newList = newFields[index].text;
                                    newList.push("");
                                    newFields[index] = { ...newFields[index], text: newList };
                                    setFields(newFields);
                                 }}
                              >
                                 Add list item
                              </button>
                              {/* <textarea
                                 name={"field_paragraph_" + index}
                                 id={"field_paragraph_" + index}
                                 onChange={(e) => {
                                    const newFields = [...fields];
                                    newFields[index] = { ...newFields[index], text: e.target.value };
                                    setFields(newFields);
                                 }}
                              /> */}
                           </label>
                        )}
                        {field.type && field.type === "code" && (
                           <>
                              <label htmlFor={"field_code_lang_" + index}>
                                 Level:
                                 <select
                                    defaultValue={1}
                                    name={"field_code_lang_" + index}
                                    id={"field_code_lang_" + index}
                                    onChange={(e) => {
                                       const newFields = [...fields];
                                       newFields[index] = { ...newFields[index], lang: e.target.value };
                                       setFields(newFields);
                                    }}
                                 >
                                    {langList.map((lang) => (
                                       <option key={lang + index} value={lang}>
                                          {lang}
                                       </option>
                                    ))}
                                 </select>
                              </label>
                              <label htmlFor={"field_code_" + index} className="article-text">
                                 Text:
                                 <div
                                    name={"field_code_" + index}
                                    id={"field_code_" + index}
                                    rows="50"
                                    cols="50"
                                    onInput={(e) => {
                                       const newFields = [...fields];
                                       newFields[index] = { ...newFields[index], text: getTextareaRowsToArray(e.target.innerText) };
                                       setFields(newFields);
                                    }}
                                    contentEditable
                                    className="editableDiv"
                                 ></div>
                              </label>
                           </>
                        )}
                        {field.type && field.type === "heading" && (
                           <>
                              <label htmlFor={"field_heading_level_" + index}>
                                 Level:
                                 <select
                                    defaultValue={1}
                                    name={"field_heading_level_" + index}
                                    id={"field_heading_level_" + index}
                                    onChange={(e) => {
                                       const newFields = [...fields];
                                       newFields[index] = { ...newFields[index], level: Number(e.target.value) };
                                       setFields(newFields);
                                    }}
                                 >
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                 </select>
                              </label>
                              <label htmlFor={"field_heading_" + index}>
                                 Text:
                                 <input
                                    type="text"
                                    name={"field_heading_" + index}
                                    id={"field_heading_" + index}
                                    onChange={(e) => {
                                       const newFields = [...fields];
                                       newFields[index] = { ...newFields[index], text: e.target.value };
                                       setFields(newFields);
                                    }}
                                 />
                              </label>
                           </>
                        )}
                        {field.type && field.type === "image" && (
                           <label htmlFor={"field_image_" + index} className="article-text">
                              Photo:
                              <input
                                 type="text"
                                 name={"field_image_link_" + index}
                                 id={"field_image_link_" + index}
                                 // ref={thumbnailRef}
                                 onChange={(e) => {
                                    e.preventDefault();
                                    const newFields = [...fields];

                                    const file = e.target.value;
                                    newFields[index] = { ...newFields[index], path: file };
                                    setFields(newFields);
                                    document.getElementById("field_image_" + index).value = "";
                                 }}
                              />
                              <input
                                 type="file"
                                 name={"field_image_" + index}
                                 id={"field_image_" + index}
                                 multiple={false}
                                 accept=".svg,.jpg,.png,.jpeg"
                                 onChange={(e) => {
                                    e.preventDefault();
                                    const newFields = [...fields];
                                    const file = e.target.files[0];
                                    newFields[index] = { ...newFields[index], path: file };
                                    setFields(newFields);
                                    document.getElementById("field_image_link_" + index).value = "";
                                 }}
                              />
                           </label>
                        )}
                     </div>
                  ))}
                  <button onClick={() => setFields((prevFields) => [...prevFields, { type: "heading" }])}>Add field</button>
                  <button onClick={handleSubmit}>Submit</button>
               </div>
            </div>
         )}
      </>
   );
};

export default AddArticle;
