import React, { useState } from "react";
// import { NavLinka } from "../components/NavbarElements";
import Footer from "../components/Footer";

const Portofolio = () => {
   const [zIndex, setZIndex] = useState(0);
   const [zoom, setZoom] = useState(0);

   const appLinks = {
      tomorrow: [
         <>
            {/* <NavLinka className="primaryBtn" to="/contact" activeStyle>
               Contact us
            </NavLinka>
            <NavLinka to="/about" activeStyle>
               Need Guidance?
            </NavLinka> */}
         </>,
      ],
      budgetGamer: [
         <>
            <a className="badge" href="https://play.google.com/store/apps/details?id=com.tomorrowit.budgetgamer" target="_blank" rel="noopener noreferrer">
               <img src="./images/Google_Play_Store_badge.svg" alt="" />
            </a>
            <a className="badge" href="https://apps.apple.com/app/id6443675890" target="_blank" rel="noopener noreferrer">
               <img src="./images/Apple_App_Store_badge.svg" alt="" />
            </a>
         </>,
      ],
   };
   const [links, setLinks] = useState(appLinks.tomorrow);

   const appDesc = {
      tomorrow: {
         title: "Portfolio",
         paragraph: "In this section, you can use the phone to navigate through our company's project catalogue or check out some of our open source projects.",
         span0: "When selecting a specific app, you can open it, and see a description in this segment.",
         head1: "Have fun!",
      },
      budgetGamer: {
         link: "https://budget-gamer-debug.web.app/",
         title: "Budget Gamer",
         paragraph:
            "Never lose a game again. Game stores often have time limited giveaways or bundles for free games on PC, Xbox and Playstation, so you have to be fast to not miss them. Budget Gamer checks your favorite stores like Steam, Epic Games, Xbox, GoG, Humble Bundle and Playstation, so you don't have to. The most common are Steam Free Weeks and Free Weekends and the Free Epic Games of the Week from the Epic Games Store, which are free-to-keep!",
         span0: "Budget Gamer is a project composed of 2 native apps (Android & iOS) and a website. The purpose of this app is to automatically notify gamers whenever there is a new free game available to redeem.",
         head1: "Tools involved:",
         span1: [
            <>
               <ul>
                  <li>Android Studio, XCode, Visual Studio Code, Adobe XD, Postman</li>
                  <li>Kotlin, Java, Swift, UIKit, JavaScript</li>
                  <li>Firebase Authentication, FB Storage, FB Real-time Database, FB Messaging (Push notifications), FB Firestore, FB Cloud functions, FB Crashlytics</li>
               </ul>
            </>,
         ],
      },
      resourceHunter: {
         link: "https://2morrow-it-solutions.github.io/resource-hunter/",
         title: "Resource Hunter",
         paragraph: "Resource Hunter is a C# open-source project, that takes an Excel file that contains translation data and their respective codes, and translates it into language files for native Android projects and iOS ones.",
      },
      appStore: {
         link: "/StoreB2B",
         title: "2Morrow Store B2B",
         paragraph:
            "A digital distribution platform for mobile applications. Our digital marketplace serves as a software delivery platform for companies to distribute their internal apps to their collaborators from their organisation. Enjoy a seamless browsing, install and update experience, quick downloads, and automated updates. The app uses the latest APIs to ensure the best possible experience a user can have on their device.",
         head0: "App store designed for businesses.",
         span0: "Streamline your app deployment and management with our intuitive web interface and seamless download experience. Publish and update internal apps with ease, ensuring your team always has access to your most updated tools. With a focus on security and efficiency, 2Morrow Store B2B is the one-stop solution for managing your company's mobile apps.",
         span1: "Try it on your own using these credentials:",
         span2: [
            <>
               <b>Email:</b> <i>b2btest@2morrowit.com</i>
               <br />
               <b>Password:</b> <i>Qwerty123!</i>
            </>,
         ],
      },
      // opBlog: {
      //    title: "Open-source Blog",
      //    paragraph: "This is a very cool description of the project. Please fill this in when we got more information about the project, or if we are actually going to develop it.",
      //    head0: "Mollit enim laborum et volupta",
      //    span0: "Occaecat nulla proident voluptate officia id aliquip aute adipisicing proident consectetur. Aliqua proident aliqua elit  incididunt",
      //    head1: "Exercitation labore ut culpa c",
      //    span1: "Culpa sint duis ipsum tempor dolore eu incididunt eu excepteur pariatur officia id id ipsum eu est ipsum do eu. Consequat ad magna",
      // },
   };
   const [app, setApp] = useState(appDesc.tomorrow);

   const openApp = (index) => {
      if (index === 0) {
         setZoom("zoomOut");
         setApp(appDesc.tomorrow);
         setLinks(appLinks.tomorrow);
         setTimeout(() => {
            setZIndex(index);
         }, 200); // Delay to allow the "zoomOut" animation to complete
      } else {
         switch (index) {
            case 1:
               setZoom("zoomIn");
               setZIndex(index);
               setApp(appDesc.budgetGamer);
               setLinks(appLinks.budgetGamer);
               break;

            case 2:
               setZoom("zoomIn");
               setZIndex(index);
               setApp(appDesc.resourceHunter);
               setLinks(appLinks.resourceHunter);
               break;
            case 3:
               setZoom("zoomIn");
               setZIndex(index);
               setApp(appDesc.appStore);
               setLinks(appLinks.appStore);
               break;
            default:
               break;
         }
      }
   };
   return (
      <>
         <div className="main">
            <div className="portofolio-section">
               <div className="headline">
                  <h1>Check our projects</h1>
                  <p>You can interact with the mobile phone below. Just select the project, navigate through it, and use the home button to go back.</p>
               </div>
               <div className="portofolio-info">
                  <div className="phone-section">
                     <div className="phone-out">
                        <div className="volume-btns">
                           <div></div>
                           <div style={{ marginTop: "10px" }}></div>
                        </div>
                        <div className="power-btn"></div>
                        <div className="phone-in">
                           <div className="upper-screen">
                              <div className="camera">
                                 <img src="./images/lens-svgrepo-com.svg" alt="" />
                              </div>
                              <div className="ear-piece"></div>
                           </div>
                           <div id="screen">
                              <div id="main-screen">
                                 <div className="signal">
                                    <div style={{ height: "40%" }}></div>
                                    <div style={{ height: "60%" }}></div>
                                    <div style={{ height: "80%" }}></div>
                                    <div style={{ height: "100%" }}></div>
                                 </div>
                                 <span id="time">22:22</span>
                                 <div className="battery">
                                    <div className="bat-in"></div>
                                    <div className="bat-out"></div>
                                 </div>
                                 <div className="our-apps">
                                    <div
                                       id="budgetgamer"
                                       onClick={() => {
                                          openApp(1);
                                       }}
                                    >
                                       <img src="./images/App Icon Dark.svg" alt="" />
                                       <span>Budget Gamer</span>
                                    </div>
                                    <div
                                       id="resourceHunter"
                                       onClick={() => {
                                          openApp(2);
                                       }}
                                    >
                                       <img src="./images/repoApp.svg" alt="" />
                                       <span>Resorce Hunter</span>
                                    </div>
                                    <div
                                       id="appStore"
                                       onClick={() => {
                                          openApp(3);
                                       }}
                                    >
                                       <img src="./images/launcher-store.png" alt="" />
                                       <span>2Morrow Store</span>
                                    </div>
                                 </div>
                                 <div className="central-apps">
                                    <a href="tel:+40790545051">
                                       <img src="./images/phoneApp.svg" alt="" />
                                    </a>
                                    <a href="mailto:contact@2morrowit.com">
                                       <img src="./images/emailApp.svg" alt="" />
                                    </a>
                                    <a href="https://github.com/2Morrow-IT-Solutions" target="_blank" rel="noopener noreferrer">
                                       <img src="./images/githubApp.svg" alt="" />
                                    </a>
                                    <a href="https://www.linkedin.com/company/2morrow-it/" target="_blank" rel="noopener noreferrer">
                                       <img src="./images/linkedinApp.svg" alt="" />
                                    </a>
                                 </div>
                                 <div className="main-apps"></div>
                                 <img className="phone-logo" src="./images/Color Favicon.svg" alt="" />
                              </div>

                              <iframe title="BudgetGamer" className={`${zoom}`} style={{ zIndex: zIndex }} src={app.link} frameBorder="0"></iframe>
                           </div>
                           <button
                              id="home-btn"
                              style={{ zIndex: zIndex }}
                              onClick={() => {
                                 openApp(0);
                              }}
                           ></button>
                        </div>
                     </div>
                  </div>
                  <div className="info-section">
                     <h1>{app.title}</h1>
                     <p>{app.paragraph}</p>
                     {app.head0 && <h2>{app.head0}</h2>}
                     {app.span0 && <span>{app.span0}</span>}
                     {app.head1 && <h2>{app.head1}</h2>}
                     {app.span1 && <span>{app.span1}</span>}
                     {app.head2 && <h2>{app.head2}</h2>}
                     {app.span2 && <span>{app.span2}</span>}
                     {app.head3 && <h2>{app.head3}</h2>}
                     {app.span3 && <span>{app.span3}</span>}
                     {links && <div className="cta-Div">{links}</div>}
                  </div>
               </div>
            </div>
            <img className="porto-img" src="./images/blob.svg" alt="" />
         </div>
         <Footer></Footer>
      </>
   );
};

export default Portofolio;
