import React, { useState } from "react";
const UpdatesB2B = () => {
   const [check, setCheck] = useState(false);
   function checkUpdates() {
      setCheck(true);
      setTimeout(() => {
         setCheck(false);
      }, 3000);
   }
   return (
      <>
         <div className="mainStore">
            <div className="header">
               <h2>Updates</h2>
            </div>
            <div className="updateList">
               <p>No updates available</p>
               <p>Check for updates?</p>
               {!check && <button onClick={() => checkUpdates()}>Check now</button>}
               {check && <img className="loadAny" src="/images/spinner.svg" alt="" />}
            </div>
         </div>
      </>
   );
};

export default UpdatesB2B;
