import React from "react";
import { PopupModal } from "react-calendly";

class CustomCalendlyButton extends React.Component {
   constructor(props) {
      super(props);

      this.state = {
         isOpen: false,
      };
   }

   render() {
      return (
         <>
            <button className="calendlyButton" onClick={() => this.setState({ isOpen: true })}>
               <span class="btn__circle">
                  <span>Schedule call</span>
               </span>
            </button>

            <PopupModal
               url="https://calendly.com/2morrow-it-solutions/30min?hide_gdpr_banner=true"
               onModalClose={() => this.setState({ isOpen: false })}
               open={this.state.isOpen}
               /*
                * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
                * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
                */
               rootElement={document.getElementById("root")}
            />
         </>
      );
   }
}

export default CustomCalendlyButton;
