import React from "react";

const Popup = ({ title, description, buttons }) => {
   const handleButtonClick = (callback) => {
      if (callback) {
         callback();
      }
      // Close the popup (you can implement this logic)
   };
   return (
      <div className="popup-overlay-store">
         <div className="popup-content">
            <div className="popup-header">
               <h2>{title}</h2>
            </div>
            <div className="popup-body">
               <span>
                  {React.Children.toArray(description).length > 0 &&
                     React.Children.toArray(description).map((child, index) => {
                        return React.cloneElement(child, { key: index });
                     })}
               </span>
            </div>
            <div className="popup-footer">
               {buttons.map((button, index) => (
                  <button key={index} onClick={() => handleButtonClick(button.onClick)} className={button.className}>
                     {button.label}
                  </button>
               ))}
            </div>
         </div>
      </div>
   );
};

export default Popup;
