import React from "react";
import Footer from "../components/Footer";
/* eslint-disable-next-line */
const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const Contact = () => {
   async function trowError(e) {
      e.classList.add("bounce");
      setTimeout(function () {
         //remove the class so animation can occur as many times as user triggers event, delay must be longer than the animation duration and any delay.
         e.classList.remove("bounce");
      }, 1000);
   }

   async function sendMail() {
      let name = document.querySelector("#name");
      let lastName = document.querySelector("#last-name");
      let email = document.querySelector("#email");
      let phone = document.querySelector("#phone");
      let companyName = document.querySelector("#company-name");
      let helpText = document.querySelector("#help-text");
      let submitBtn = document.querySelector("#submit-btn");
      async function popUp(state) {
         try {
            let opened = document.querySelector(`[data-popup-opened= "true"]`);
            if (opened) {
               opened.style.opacity = "0";
               opened.style.pointerEvents = "none";
               opened.removeAttribute("data-popup-opened");
            }
         } catch (error) {}

         const popupModal = document.querySelector(`[data-popup-modal="${state}"]`);
         let bodyBlackout = document.getElementById(`body-blackout`);
         popupModal.style.opacity = "1";
         popupModal.style.pointerEvents = "auto";
         bodyBlackout.style.display = "block";
         popupModal.setAttribute("data-popup-opened", "true");

         popupModal.querySelector(".error").addEventListener("click", async () => {
            await popupModal.style.removeProperty("opacity");
            popupModal.style.pointerEvents = "none";
            await bodyBlackout.style.removeProperty("display");
            popupModal.removeAttribute("data-popup-opened");
            if (state) {
               name.value = "";
               lastName.value = "";
               email.value = "";
               phone.value = "";
               companyName.value = "";
               helpText.value = "";
            }
         });
         setTimeout(() => {
            async function removePop() {
               await popupModal.style.removeProperty("opacity");
               popupModal.style.pointerEvents = "none";
               await bodyBlackout.style.removeProperty("display");
               popupModal.removeAttribute("data-popup-opened");
               setTimeout(() => {
                  bodyBlackout.removeEventListener("click", removePop);
               }, 200);
            }
            bodyBlackout.addEventListener("click", (e) => {
               if (e.target.id === "body-blackout") removePop();
            });
         }, 500);
      }
      if (name.value === "") trowError(name);
      if (lastName.value === "") trowError(lastName);
      if (!email.value.match(emailRegex) || email.value === "") trowError(email);
      if (helpText.value === "") trowError(helpText);
      if (name.value !== "" && lastName.value !== "" && email.value !== "" && helpText.value !== "" && email.value.match(emailRegex)) {
         let text = submitBtn.querySelector(".text-submit");
         let textSent = submitBtn.querySelector(".text-sent");
         let loading = submitBtn.querySelector("svg");
         text.style.opacity = "0";
         loading.style.opacity = "1";
         var requestOptions = {
            method: "POST",
            redirect: "follow",
         };
         setTimeout(() => {
            fetch(`https://europe-west1-morrow-it-solutions-web.cloudfunctions.net/sendForm?firstName=${name.value}&lastName=${lastName.value}&email=${email.value}&phoneNumber=${phone.value}&companyName=${companyName.value}&message=${helpText.value}`, requestOptions)
               .then((response) => response.text())
               .then((result) => {
                  popUp(true);
                  loading.style.opacity = "0";
                  textSent.style.opacity = "1";
                  setTimeout(() => {
                     textSent.style.opacity = "0";
                     text.style.opacity = "1";
                  }, 5000);
                  // console.log(result);
               })
               .catch((error) => {
                  popUp(false);
                  let textError = submitBtn.querySelector(".text-error");
                  loading.style.opacity = "0";
                  textError.style.opacity = "1";
                  setTimeout(() => {
                     textError.style.opacity = "0";
                     text.style.opacity = "1";
                  }, 5000);
                  // console.log("error", error);
               });
         }, 600);
      }
   }

   return (
      <>
         <div className="main main-contact">
            <div className="headline-contact">
               <h1>Contact us</h1>
               <p>Feel free to contact us directly, or by filling out the form below.</p>
            </div>
            <div className="contact">
               <div className="contact-info">
                  <div className="info-contc">
                     <h3 className="hidden">Contact Info</h3>
                     <a href="tel:+40790545051">
                        <img src="./images/telephone.svg" alt="" />
                        <span>+40 790 545 051</span>
                     </a>
                     <a href="mailto:contact@2morrowit.com">
                        <img src="./images/email.svg" alt="" />
                        <span>contact@2morrowit.com</span>
                     </a>
                     <a href="https://goo.gl/maps/pYqzUkXLvLqbqQLj9" target="_blank" rel="noopener noreferrer">
                        <img src="./images/geo-pin.svg" alt="" />
                        <span>Oltului 25, Galaţi, Romania.</span>
                     </a>
                  </div>

                  <div className="social-info">
                     <h3>Social Accounts</h3>
                     <a href="https://www.linkedin.com/company/2morrow-it/" target="_blank" rel="noopener noreferrer">
                        <img src="./images/linkedin.svg" alt="" />
                        <span>LinkedIn</span>
                        <img src="./images/box-arrow.svg" alt="" />
                     </a>
                     <a href="https://github.com/2Morrow-IT-Solutions" target="_blank" rel="noopener noreferrer">
                        <img src="./images/github.svg" alt="" />
                        <span>Github</span>
                        <img src="./images/box-arrow.svg" alt="" />
                     </a>
                     <a href="https://play.google.com/store/apps/dev?id=5856409625574190258" target="_blank" rel="noopener noreferrer">
                        <img src="./images/Google_Play_logo.svg" className="playStoreLogo" alt="" />
                        <span>Google Play</span>
                        <img src="./images/box-arrow.svg" alt="" />
                     </a>
                     <a href="https://apps.apple.com/us/developer/2morrow-it-solutions-srl/id1648289345" target="_blank" rel="noopener noreferrer">
                        <img src="./images/App_Store.svg" alt="" />
                        <span>App Store</span>
                        <img src="./images/box-arrow.svg" alt="" />
                     </a>
                  </div>

                  <div id="map">
                     <iframe
                        id="google-maps"
                        title="maps"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2800.3039586478612!2d28.014804676679343!3d45.42337373616114!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40b6df1e3fab7363%3A0xa6c119f4f3af561d!2s2Morrow%20IT%20Solutions!5e0!3m2!1sro!2sro!4v1685963855829!5m2!1sro!2sro"
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                     ></iframe>
                  </div>
                  {/* <img className="dots" src="./images/dots.png" alt="" /> */}
               </div>
               <div className="contact-form">
                  <div className="pearson-info">
                     <div>
                        <label htmlFor="name">First Name*</label>
                        <input id="name" type="text" required placeholder="Joe" />
                     </div>
                     <div>
                        <label htmlFor="last-name">Last Name*</label>
                        <input id="last-name" type="text" required placeholder="Doe" />
                     </div>
                     <div>
                        <label htmlFor="email">Email*</label>
                        <input id="email" type="email" required placeholder="example@email.com" />
                     </div>
                     <div>
                        <label htmlFor="phone">Phone Number</label>
                        <input id="phone" type="tel" required placeholder="+44712345678" />
                     </div>
                  </div>
                  <label htmlFor="company-name">Company Name</label>
                  <input id="company-name" type="text" required placeholder="Your company name" />
                  <label htmlFor="help-text">How can we help?*</label>
                  <textarea id="help-text" type="text" required placeholder="Please share what you want us to help with"></textarea>
                  <button
                     id="submit-btn"
                     type="submit"
                     onClick={() => {
                        sendMail();
                     }}
                  >
                     <span className="text-submit">Submit</span>
                     <svg className="spinner_wait" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                        <circle className="path_wait" fill="none" stroke-width="8" stroke-linecap="round" cx="33" cy="33" r="27"></circle>
                     </svg>
                     <span className="text-sent">Sent</span>
                     <span className="text-error">Please try again later.</span>
                  </button>
               </div>
            </div>
            <img className="contact-img" src="./images/blob.svg" alt="" />
         </div>
         <Footer></Footer>
         <div id="body-blackout">
            <div className="popup-modal" data-popup-modal="false">
               <h3>Error</h3>
               <p>Please try again later.</p>
               <button class="error">Ok</button>
            </div>
            <div class="popup-modal" data-popup-modal="true">
               <h3>Success</h3>
               <p>Thank you for contacting us. Your request has been received and is being processed. We will respond to you shortly.</p>
               <button class="error">Ok</button>
            </div>
         </div>
      </>
   );
};

export default Contact;
