import React from "react";
import ShareAnchor from "./ShareAnchor";
const TeamMember = (props) => {
   let i = true;
   let x = 0;
   let anchors = [];
   while (i === true) {
      if (props["anchor" + x]) {
         anchors.push(<ShareAnchor href={props["anchor" + x]} type={props["anchor" + x + "type"]}></ShareAnchor>);
         x++;
      } else {
         i = false;
      }
   }
   return (
      <div className="team-member">
         <img className="profilePhoto" src={props.srcImg} alt="" />
         <h3 className="name">{props.name}</h3>
         <p className="title">{props.title}</p>
         <p className="description">{props.description}</p>
         <div className="anchors">{anchors}</div>
      </div>
   );
};

export default TeamMember;
