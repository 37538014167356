import React from "react";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

export const formatTimestamp = (timestampInSeconds) => {
   // Convert timestamp to milliseconds
   const timestampInMilliseconds = timestampInSeconds * 1000;

   // Create a new Date object
   const date = new Date(timestampInMilliseconds);

   // Get the month name
   const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
   const month = monthNames[date.getMonth()];

   // Get the day and year
   const day = date.getDate();
   const year = date.getFullYear();

   // Construct the formatted date string
   const formattedDate = `${month} ${day}, ${year}`;

   return formattedDate;
};
export const estimateReadTime = (article) => {
   // Function to count words in a string
   function countWords(text) {
      return text.split(/\s+/).length;
   }

   // Recursive function to count words in nested objects
   function countWordsInObject(obj) {
      let wordCount = 0;
      for (const key in obj) {
         const value = obj[key];
         if (typeof value === "string") {
            wordCount += countWords(value);
         } else if (typeof value === "object") {
            wordCount += countWordsInObject(value);
         }
      }
      return wordCount;
   }

   // Calculate total word count
   const totalWordCount = countWordsInObject(article);

   // Average reading speed in words per minute (WPM)
   const averageReadingSpeed = 180; // Adjust this value as needed

   // Calculate estimated read time in minutes
   const estimatedReadTimeMinutes = totalWordCount / averageReadingSpeed;

   return Math.ceil(estimatedReadTimeMinutes);
};

export function Linkify({ text }) {
   // Regular expression to find URLs in the text
   // eslint-disable-next-line no-useless-escape
   const urlRegex = /(?:\(|\[|\{)(https?:\/\/[^\)\]\}]+)(?:\)|\]|\})/g;

   // Function to replace URLs with HTML anchor tags
   const linkifyText = (text) => {
      return text.replace(urlRegex, (match, url) => {
         return `${match.split(url)[0]}<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>${match.split(url)[1]}`;
      });
   };

   // Function to render the HTML with links
   const renderHTML = (htmlString) => {
      return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
   };

   const processedText = linkifyText(text);

   return renderHTML(processedText);
}

export const uploadFileAndGetURL = (file, storagePath, storage) => {
   // console.log(file.type);
   return new Promise((resolve, reject) => {
      const fileRef = ref(storage, storagePath);
      const uploadTask = uploadBytesResumable(fileRef, file, { contentType: file.type, cacheControl: "public,max-age=2628000" });

      uploadTask.on(
         "state_changed",
         (snapshot) => {
            // eslint-disable-next-line no-unused-vars
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
         },
         (error) => {
            reject(error);
         },
         async () => {
            const downloadURL = await getDownloadURL(fileRef);
            resolve(downloadURL);
         }
      );
   });
};
