import React from "react";
// eslint-disable-next-line no-unused-vars
import { auth } from "../firebase/init";
// import { Timestamp, collection, setDoc, doc } from "firebase/firestore";
import { signInWithEmailAndPassword, setPersistence, browserLocalPersistence } from "firebase/auth";
import { useNavigate } from "react-router-dom";

/* eslint-disable-next-line */
const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
async function trowError(e) {
   e.classList.add("bounce");
   setTimeout(function () {
      e.classList.remove("bounce");
   }, 1000);
}

const Login = () => {
   const navigate = useNavigate();
   async function loginEvent() {
      const email = document.getElementById("email");
      const password = document.getElementById("password");
      if (!email.value.match(emailRegex) || email.value === "") {
         trowError(email);
      } else {
         await setPersistence(auth, browserLocalPersistence);

         await signInWithEmailAndPassword(auth, email.value, password.value)
            .then(async (userCredential) => {
               const user = userCredential.user;
               console.log(user);

               return navigate("/addArticle");
            })
            .catch(async function (error) {
               // Handle Errors here.
               console.log(error);
            });
      }
   }

   return (
      <>
         <div className="main loginPage">
            <div className="loginForm">
               <img src="./images/million_logo_black.svg" alt="" />
               <form
                  onSubmit={(e) => {
                     e.preventDefault(); // This prevents the page from reloading
                     loginEvent();
                  }}
               >
                  <div className="login-input">
                     <div>
                        <label htmlFor="email">email</label>
                        <input id="email" type="email" required placeholder="example@email.com" />
                     </div>
                     <div>
                        <label htmlFor="password">pass</label>
                        <input id="password" type="password" required placeholder="Enter your password here" />
                     </div>
                  </div>
                  <button className="primaryBtn" id="submitLogin" type="submit">
                     login
                  </button>
               </form>
            </div>
         </div>
      </>
   );
};

export default Login;
