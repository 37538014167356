import React, { useState, useEffect } from "react";
import Footer from "../components/Footer";
import CustomCalendlyButton from "../components/Calendly";
const Help = () => {
   const DivComponent = (props) => {
      const [showParagraph, setShowParagraph] = useState(false);

      const handleButtonClick = () => {
         setShowParagraph(!showParagraph);
      };

      return (
         <div className="question" style={{ background: showParagraph ? "#f3faff" : "transparent" }}>
            <div>
               <h4 style={{ fontWeight: showParagraph ? "600" : "400" }}>{props.question}</h4>
               {showParagraph && <p>{props.answer}</p>}
            </div>

            <img src="./images/down-arrow.svg" style={{ rotate: showParagraph ? "0deg" : "-90deg" }} alt="" onClick={handleButtonClick} />
         </div>
      );
   };

   useEffect(() => {
      // let cadFrame = document.querySelector('[data-id="branding"]');
      // interior.style.height = "1000px";
   });
   // const [calendly, setCalendy] = useState(false);
   return (
      <>
         <div className="main main-help">
            <h1 className="head-help">Schedule a meeting with us</h1>
            <div className="schedule" id="aici">
               <div className="ilustration">
                  <div className="head-message">
                     <img src="./images/question-octagon.svg" alt="" />
                     <h3>Ask us anything!</h3>
                  </div>
                  <div className="messages">
                     <div className="mess">
                        <p>Qui voluptate laboris ut do excepteur elit eu </p>
                        <span>12:01</span>
                     </div>
                     <div className="mess">
                        <p>Occaecat exercitation laboris in anim adipisicing adipisicing tempor in eu cupidata</p>
                        <span>12:03</span>
                     </div>
                     <div className="mess">
                        <p>Occaecat exercitation laboris in anim adipisicing adipisicing tempor in eu cupidata</p>
                        <span>12:05</span>
                     </div>
                     <div className="mess">
                        <p>Occaecat exercitation laboris in anim adipisicing adipisicing tempor in eu cupidata</p>
                        <span>12:07</span>
                     </div>
                  </div>
                  <img className="chat-icon" src="./images/chat-left.svg" alt="" />
               </div>
               <div className="promo">
                  <div className="price">
                     <h2>$25</h2>
                     <div className="line"></div>
                     <span>for the first call</span>
                  </div>
                  <div className="headline">
                     <h2>Guidance call</h2>
                  </div>
                  <div className="talk-points">
                     <h4>Topics we can help with:</h4>
                     <ul>
                        <li>
                           <img src="./images/check-circle.svg" alt="" />
                           <span>Project planning and strategy</span>
                        </li>
                        <li>
                           <img src="./images/check-circle.svg" alt="" />
                           <span>Technical expertise and guidance</span>
                        </li>
                        <li>
                           <img src="./images/check-circle.svg" alt="" />
                           <span>Agile development and project management</span>
                        </li>
                        <li>
                           <img src="./images/check-circle.svg" alt="" />
                           <span>Quality assurance and testing</span>
                        </li>
                        <li>
                           <img src="./images/check-circle.svg" alt="" />
                           <span>Scalability and performance optimization</span>
                        </li>
                        <li>
                           <img src="./images/check-circle.svg" alt="" />
                           <span>Security and data protection</span>
                        </li>
                     </ul>
                  </div>
               </div>

               <div className="schedule-button">
                  <p>We are excited to have a chat with you, and help with your project idea. We can guide you throughout all the different stages of development, so don't hesitate to use the button below to book a call.</p>
                  <CustomCalendlyButton></CustomCalendlyButton>
               </div>
            </div>
            <img className="help-img" src="./images/blob.svg" alt="" />
            <div className="faq-section">
               <div className="faq-questions">
                  <h1>Non cupidatat veniam</h1>
                  <DivComponent question="Do you have questions about your future project?" answer="Incididunt ex irure ad non Lorem culpa fugiat exercitation ullamco esse minim tempor quis l" />
                  <DivComponent question="Do you require assistance with technical requirements?" answer="Incididunt ex irure ad non Lorem culpa fugiat  ullamco esse minim tempor quis l" />
                  <DivComponent question="Would you like to hear an opinion from people with experience in the field?" answer="Incididunt ex irure ad non Lorem culpa fugiat exercitation ullamco esse minim tempor quis l" />
                  <DivComponent question="Magna irure ex dolore ?" answer="Incididunt ex irure ad non Lorem culpa fugiat exercitation ullamco esse minim tempor quis l" />
               </div>
            </div>
         </div>

         <Footer></Footer>
      </>
   );
};

export default Help;
