import React from "react";

const OpenAppDetails = ({ details, close }) => {
   console.log(details);
   return (
      <>
         <div className={details ? "openDetailsPage active" : "openDetailsPage"}>
            {details && (
               <>
                  <div className="header">
                     <div className="backBtn">
                        <button
                           onClick={() => {
                              close();
                           }}
                        >
                           <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                              <path d="M647-440H160v-80h487L423-744l57-56 320 320-320 320-57-56 224-224Z" />
                           </svg>
                        </button>
                     </div>
                     <h2>{details.title}</h2>
                  </div>
                  <div className="app">
                     <p>{details.desc}</p>
                  </div>
               </>
            )}
         </div>
      </>
   );
};

export default OpenAppDetails;
