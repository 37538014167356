import React, { useState } from "react";
import Popup from "./popups";
const Switch = ({ isOn, handleToggle, field, disabled }) => {
   const [value, setValue] = useState(isOn);
   const [switchClr, setSwitchClr] = useState({ active: "#d0d1da", none: "#b9b9bd" });
   const [buttonClr, setButtonClr] = useState({ active: "#555c6f", none: "#ececec" });

   window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", (e) => {
      if (e.matches) {
         setSwitchClr({ active: "#474953", none: "#5a5a5e" });
         setButtonClr({ active: "#bbc2d8", none: "#b9b9b9" });
         console.log("Dark mode is now preferred");
      } else {
         setSwitchClr({ active: "#d0d1da", none: "#b9b9bd" });
         setButtonClr({ active: "#555c6f", none: "#ececec" });
         console.log("Light mode is now preferred");
      }
   });
   return (
      <>
         <div className="columnCheck">
            <input
               defaultChecked={value}
               onChange={(e) => {
                  if (disabled) {
                     e.target.checked = value;
                  } else {
                     handleToggle(e.target.checked);
                     setValue(e.target.checked);
                  }
               }}
               className="switch-checkbox"
               id={`switch` + field}
               type="checkbox"
            />
            <label style={{ backgroundColor: value ? switchClr.active : switchClr.none }} className="switch-label" htmlFor={`switch` + field}>
               <span className={`switch-button`} style={{ backgroundColor: value ? buttonClr.active : buttonClr.none }} />
            </label>
         </div>
      </>
   );
};
const SettingsB2B = () => {
   const [popup, setPopup] = useState(null); // State to manage the popup
   const [addToHomeScreen, setAddToHomeScreen] = useState(false);
   const [syncInterval, setSyncInterval] = useState("6 Hours");
   const [checkUpdatesOver, setCheckUpdatesOver] = useState("Wifi only");

   const [searchAutUpdates, setSearchAutUpdates] = useState(false);
   const [downAutUpdates, setDownAutUpdates] = useState(false);
   const [downUpdatesCharge, setDownUpdatesCharge] = useState(false);
   const [downUpdatesOver, setDownUpdatesOver] = useState("Wifi only");
   return (
      <>
         <div className="mainStore">
            <div className="header">
               <h2>Settings</h2>
            </div>
            <div className="settingsList">
               <h4>Settings</h4>
               <div className="toggleOption">
                  <div className="toggDesc">
                     <p>Add new apps to Home Screen</p>
                  </div>
                  <Switch
                     isOn={addToHomeScreen}
                     handleToggle={(e) => {
                        setAddToHomeScreen(e);
                     }}
                     field={"Home_Screen"}
                  />
               </div>
               <div className="toggleOption">
                  <div className="toggDesc">
                     <p>Search for updates automatically</p>
                     <span>Run a background job that checks if you have the lastest app updates</span>
                  </div>
                  <Switch
                     isOn={searchAutUpdates}
                     handleToggle={(e) => {
                        setSearchAutUpdates(e);
                     }}
                     field={"Search_updates_automatically"}
                  />
               </div>
               <div className={searchAutUpdates ? "togglePop" : "togglePop disabled"}>
                  <div
                     className="toggDesc"
                     onClick={() =>
                        searchAutUpdates
                           ? setPopup({
                                title: `Sync interval`,
                                description: [
                                   <div className="intervals">
                                      <label>
                                         <input
                                            type="radio"
                                            name="option"
                                            value="6 Hours"
                                            checked={syncInterval === "6 Hours"}
                                            onChange={(e) => {
                                               setSyncInterval(e.target.value);
                                               setPopup(null);
                                            }}
                                         />
                                         6 Hours
                                      </label>
                                      <label>
                                         <input
                                            type="radio"
                                            name="option"
                                            value="12 Hours"
                                            checked={syncInterval === "12 Hours"}
                                            onChange={(e) => {
                                               setSyncInterval(e.target.value);
                                               setPopup(null);
                                            }}
                                         />
                                         12 Hours
                                      </label>
                                      <label>
                                         <input
                                            type="radio"
                                            name="option"
                                            value="1 Day"
                                            checked={syncInterval === "1 Day"}
                                            onChange={(e) => {
                                               setSyncInterval(e.target.value);
                                               setPopup(null);
                                            }}
                                         />
                                         1 Day
                                      </label>
                                      <label>
                                         <input
                                            type="radio"
                                            name="option"
                                            value="2 Days"
                                            checked={syncInterval === "2 Days"}
                                            onChange={(e) => {
                                               setSyncInterval(e.target.value);
                                               setPopup(null);
                                            }}
                                         />
                                         2 Days
                                      </label>
                                      <label>
                                         <input
                                            type="radio"
                                            name="option"
                                            value="1 Week"
                                            checked={syncInterval === "1 Week"}
                                            onChange={(e) => {
                                               setSyncInterval(e.target.value);
                                               setPopup(null);
                                            }}
                                         />
                                         1 Week
                                      </label>
                                   </div>,
                                ],
                                buttons: [{ label: `Cancel`, onClick: () => setPopup(null) }],
                             })
                           : null
                     }
                  >
                     <p>Sync interval</p>
                     <span>{syncInterval}</span>
                  </div>
               </div>
               <div className={searchAutUpdates ? "togglePop" : "togglePop disabled"}>
                  <div
                     className="toggDesc"
                     onClick={() =>
                        searchAutUpdates
                           ? setPopup({
                                title: `Check for updates over`,
                                description: [
                                   <div className="intervals">
                                      <label>
                                         <input
                                            type="radio"
                                            name="option"
                                            value="Any network"
                                            checked={checkUpdatesOver === "Any network"}
                                            onChange={(e) => {
                                               setCheckUpdatesOver(e.target.value);
                                               setPopup(null);
                                            }}
                                         />
                                         Any network
                                      </label>
                                      <label>
                                         <input
                                            type="radio"
                                            name="option"
                                            value="Wifi only"
                                            checked={checkUpdatesOver === "Wifi only"}
                                            onChange={(e) => {
                                               setCheckUpdatesOver(e.target.value);
                                               setPopup(null);
                                            }}
                                         />
                                         Wifi only
                                      </label>
                                      <label>
                                         <input
                                            type="radio"
                                            name="option"
                                            value="Unmetered networks only"
                                            checked={checkUpdatesOver === "Unmetered networks only"}
                                            onChange={(e) => {
                                               setCheckUpdatesOver(e.target.value);
                                               setPopup(null);
                                            }}
                                         />
                                         Unmetered networks only
                                      </label>
                                   </div>,
                                ],
                                buttons: [{ label: `Cancel`, onClick: () => setPopup(null) }],
                             })
                           : null
                     }
                  >
                     <p>Check for updates over</p>
                     <span>{checkUpdatesOver}</span>
                  </div>
               </div>
               <div className="toggleOption">
                  <div className="toggDesc">
                     <p>Download updates automatically</p>
                  </div>
                  <Switch
                     isOn={downAutUpdates}
                     handleToggle={(e) => {
                        setDownAutUpdates(e);
                     }}
                     field={"Download_updates_automatically"}
                  />
               </div>
               <div className={downAutUpdates ? "togglePop" : "togglePop disabled"}>
                  <div className="toggDesc">
                     <p>Download updates only when charging</p>
                  </div>
                  <Switch
                     isOn={downUpdatesCharge}
                     handleToggle={(e) => {
                        setDownUpdatesCharge(e);
                     }}
                     field={"Download_updates_when_charging"}
                     disabled={!downAutUpdates ? true : false}
                  />
               </div>
               <div className={downAutUpdates ? "togglePop" : "togglePop disabled"}>
                  <div
                     className="toggDesc"
                     onClick={() =>
                        downAutUpdates
                           ? setPopup({
                                title: `Download updates automatically over`,
                                description: [
                                   <div className="intervals">
                                      <label>
                                         <input
                                            type="radio"
                                            name="option"
                                            value="Any network"
                                            checked={downUpdatesOver === "Any network"}
                                            onChange={(e) => {
                                               setDownUpdatesOver(e.target.value);
                                               setPopup(null);
                                            }}
                                         />
                                         Any network
                                      </label>
                                      <label>
                                         <input
                                            type="radio"
                                            name="option"
                                            value="Wifi only"
                                            checked={downUpdatesOver === "Wifi only"}
                                            onChange={(e) => {
                                               setDownUpdatesOver(e.target.value);
                                               setPopup(null);
                                            }}
                                         />
                                         Wifi only
                                      </label>
                                      <label>
                                         <input
                                            type="radio"
                                            name="option"
                                            value="Unmetered networks only"
                                            checked={downUpdatesOver === "Unmetered networks only"}
                                            onChange={(e) => {
                                               setDownUpdatesOver(e.target.value);
                                               setPopup(null);
                                            }}
                                         />
                                         Unmetered networks only
                                      </label>
                                   </div>,
                                ],
                                buttons: [{ label: `Cancel`, onClick: () => setPopup(null) }],
                             })
                           : null
                     }
                  >
                     <p>Download updates automatically over</p>
                     <span>{downUpdatesOver}</span>
                  </div>
               </div>
            </div>
            <div className="settingsList">
               <h4>Account Settings</h4>
               <div className="togglePop disabled">
                  <div className="toggDesc">
                     <p>Edit name</p>
                  </div>
               </div>
               <div className="togglePop disabled">
                  <div className="toggDesc">
                     <p>Edit password</p>
                     <span style={{ fontWeight: "700" }}>**********</span>
                  </div>
               </div>
               <div className="togglePop disabled">
                  <div className="toggDesc">
                     <p>Log out</p>
                     <span>Log out of this device. You will be prompted to log in again.</span>
                  </div>
               </div>
            </div>
            <div className="settingsList">
               <h4>App Info</h4>
               <div className="togglePop disabled">
                  <div className="toggDesc">
                     <p>Terms and conditions</p>
                  </div>
               </div>
               <div className="togglePop disabled">
                  <div className="toggDesc">
                     <p>GDPR</p>
                  </div>
               </div>
               <div className="togglePop disabled">
                  <div className="toggDesc">
                     <p>Open source libraries</p>
                  </div>
               </div>
               <div className="togglePop disabled">
                  <div className="toggDesc">
                     <p>Send Feedback</p>
                     <span>Report technical issues or suggert new features</span>
                  </div>
               </div>
               <div className="togglePop disabled">
                  <div className="toggDesc">
                     <p>App Version</p>
                     <span>Version 0.0.1</span>
                  </div>
               </div>
               <div className="mementoTag">
                  <span>Made with</span>
                  <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                     <path d="m480-120-58-52q-101-91-167-157T150-447.5Q111-500 95.5-544T80-634q0-94 63-157t157-63q52 0 99 22t81 62q34-40 81-62t99-22q94 0 157 63t63 157q0 46-15.5 90T810-447.5Q771-395 705-329T538-172l-58 52Z" fill="red" />
                  </svg>
                  <span>by</span>
                  <img src="/images/2m_logo.svg" alt="" />
               </div>
            </div>
         </div>
         {popup && <Popup title={popup.title} description={popup.description} buttons={popup.buttons} />}
      </>
   );
};

export default SettingsB2B;
