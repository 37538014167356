// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
   apiKey: "AIzaSyAU8CYI5HPPA9Yz2A3W40AAx-aritRHi7g",
   authDomain: "morrow-it-solutions-web.firebaseapp.com",
   databaseURL: "https://morrow-it-solutions-web-default-rtdb.europe-west1.firebasedatabase.app",
   projectId: "morrow-it-solutions-web",
   storageBucket: "morrow-it-solutions-web.appspot.com",
   messagingSenderId: "288354259965",
   appId: "1:288354259965:web:024b3a8c2567b4f4afbf94",
   measurementId: "G-2L1H1JYBFF",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
export default app;

export const auth = getAuth(app);
export const db = getFirestore(app);
export const rtdb = getDatabase(app);
export const storage = getStorage(app);
