// import { FaBars } from "react-icons/fa";
import React from "react";
import { NavLink as Link, useLocation } from "react-router-dom";
import styled from "styled-components";

export const NavLinka = styled(Link)`
   color: #000000; //#dff4ff;
   display: flex;
   font-size: 14px;
   letter-spacing: 1px;
   font-weight: 500;
   align-items: center;
   justify-content: center;
   text-decoration: none;
   padding: 0 1rem;
   height: 100%;
   cursor: pointer;
   scale: 0.95;
   text-align: center;
   &.active {
      color: #0076fe;
      font-size: 14.5px;
      scale: 1;
      // background-color: #006a8b; //rgb(0, 160, 208, 0.4);
   }
   :hover {
      color: #005ecb;
   }
   // @media screen and (max-width: 1024px) {
   //    padding: 0 0.8rem;
   // }
   @media screen and (max-width: 768px) {
      height: 3rem;
      width: 100%;
      &.active {
         scale: 1;
      }
   }
   @media (prefers-color-scheme: dark) {
      color: #ffffff;
   }
`;
const StyledBurger = styled.button`
   // position: absolute;
   //   top: 5%;
   //   left: 2rem;
   display: flex;
   display: none;
   flex-direction: column;
   justify-content: space-around;
   width: 2rem;
   height: 2rem;
   margin-right: 1rem;
   background: transparent;
   border: none;
   cursor: pointer;
   padding: 0;
   z-index: 10;
   align-self: center;

   &:focus {
      outline: none;
   }

   div {
      width: 2rem;
      height: 0.25rem;
      background: #000; //${({ open }) => (open ? "#0D0C1D" : "#EFFFFA")};
      border-radius: 10px;
      transition: all 0.2s linear;
      position: relative;
      transform-origin: 1px;

      :first-child {
         transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
      }

      :nth-child(2) {
         opacity: ${({ open }) => (open ? "0" : "1")};
         transform: ${({ open }) => (open ? "translateX(20px)" : "translateX(0)")};
      }

      :nth-child(3) {
         transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
      }
   }
   @media screen and (max-width: 768px) {
      display: flex;
   }
   @media (prefers-color-scheme: dark) {
      div {
         background: #fff;
      }
   }
`;

export const NavMenu = () => {
   const [open, setOpen] = React.useState(false);
   const [showNav, setShowNav] = React.useState("none");
   const toggleNav = (open) => {
      setOpen(!open);
      !open ? setShowNav("flex") : setShowNav("none");
      !open ? document.querySelector(".main").classList.add("blur") : document.querySelector(".main").classList.remove("blur");
      !open ? document.querySelector("nav").classList.add("blur") : document.querySelector("nav").classList.remove("blur");
      if (!open) {
         try {
            document.querySelector(".footer").classList.add("blur");
         } catch (error) {}
      } else {
         try {
            document.querySelector(".footer").classList.remove("blur");
         } catch (error) {}
      }
   };
   return (
      <>
         <div className={`navMenu ${showNav}`}>
            <NavLinka
               to="/"
               activeStyle
               onClick={() => {
                  toggleNav(true);
                  window.scroll(0, 0);
               }}
            >
               HOME
            </NavLinka>
            <NavLinka
               to="/about"
               activeStyle
               onClick={() => {
                  toggleNav(true);
                  window.scroll(0, 0);
               }}
            >
               ABOUT US
            </NavLinka>
            <NavLinka
               to="/portofolio"
               activeStyle
               onClick={() => {
                  toggleNav(true);
                  window.scroll(0, 0);
               }}
            >
               PORTFOLIO
            </NavLinka>
            <NavLinka
               to="/articles"
               activeStyle
               onClick={() => {
                  toggleNav(true);
                  window.scroll(0, 0);
               }}
            >
               ARTICLES
            </NavLinka>
            <NavLinka
               to="/help"
               activeStyle
               onClick={() => {
                  toggleNav(true);
                  window.scroll(0, 0);
               }}
            >
               NEED GUIDANCE?
            </NavLinka>
            <NavLinka
               to="/contact"
               activeStyle
               onClick={() => {
                  toggleNav(true);
                  window.scroll(0, 0);
               }}
            >
               CONTACT US
            </NavLinka>
         </div>
         <StyledBurger open={open} onClick={() => toggleNav(open)}>
            <div />
            <div />
            <div />
         </StyledBurger>
      </>
   );
};

export const NavLogo = () => {
   return (
      <Link to="/" className="navLogo">
         <img src="/images/logo.svg" alt="logo"></img>
      </Link>
   );
};

export const Nav = () => {
   const location = useLocation();

   // Define routes where you want to hide the Nav component
   const hiddenRoutes = ["/download"];

   // Return null if the current route matches a hidden route
   if (hiddenRoutes.includes(location.pathname)) {
      return null;
   }
   window.addEventListener("scroll", function () {
      if (window.scrollY > 30) {
         if (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) {
            document.querySelector("nav").style.backgroundColor = "rgb(19 21 23 / 96%)"; //"#e0f4ff";
         } else {
            document.querySelector("nav").style.backgroundColor = "rgb(224 244 255 / 95%)"; //"#e0f4ff";
         }
      } else {
         //remove the background property so it comes transparent again (defined in your css)
         document.querySelector("nav").style.backgroundColor = "transparent"; //"#f3faff";
      }
   });
   return (
      <nav>
         <NavLogo></NavLogo>
         <NavMenu></NavMenu>
      </nav>
   );
};
