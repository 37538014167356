import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import { db } from "../firebase/init";
import { doc, getDoc } from "firebase/firestore";
import { useNavigate, useParams } from "react-router-dom";
import { Linkify, formatTimestamp } from "../components/utils";
import { CopyBlock, dracula } from "react-code-blocks";
import { Helmet } from "react-helmet";

const Article = () => {
   const { articleId } = useParams();
   const [article, setArticle] = useState(null);
   const [share, setShare] = useState(false);
   const artRef = doc(db, "Articles", articleId);
   const navigate = useNavigate();

   // const bookmarkPage = () => {
   //    if (window.sidebar && window.sidebar.addPanel) {
   //       // Firefox <23
   //       window.sidebar.addPanel(document.title, window.location.href, "");
   //    } else if (window.external && "AddFavorite" in window.external) {
   //       // Internet Explorer
   //       window.external.AddFavorite(window.location.href, document.title);
   //    } else if ((window.opera && window.print) || (window.sidebar && !(window.sidebar instanceof Node))) {
   //       // Opera <15 and Firefox >23
   //       var elem = document.createElement("a");
   //       elem.setAttribute("href", window.location.href);
   //       elem.setAttribute("title", document.title);
   //       elem.setAttribute("rel", "sidebar");
   //       elem.click();
   //       return true;
   //    } else {
   //       // For the other browsers (mainly WebKit) we use a simple alert to inform users that they can add to bookmarks with ctrl+D/cmd+D
   //       alert("You can add this page to your bookmarks by pressing " + (navigator.userAgent.toLowerCase().indexOf("mac") !== -1 ? "Command/Cmd" : "CTRL") + " + D on your keyboard.");
   //    }
   // };

   useEffect(() => {
      const fetchArticle = async () => {
         const articleSnap = await getDoc(artRef);
         const liked = localStorage.getItem(articleSnap.id + "_liked");
         const booked = localStorage.getItem(articleSnap.id + "_booked");
         // console.log(articleSnap.data());
         // console.log(liked, booked);
         setArticle({ ...articleSnap.data(), id: articleSnap.id, likes: articleSnap.data().likes || 0, liked: liked === "true" ? true : false, booked: booked === "true" ? true : false });
      };

      fetchArticle();

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const likeArticle = async () => {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
         method: "POST",
         headers: myHeaders,
         redirect: "follow",
      };
      if (!article.liked) {
         localStorage.setItem(article.id + "_liked", "true");
         setArticle((prev) => ({ ...prev, liked: true, likes: prev.likes + 1 }));
         await fetch(`https://europe-west1-morrow-it-solutions-web.cloudfunctions.net/sendLike?article=${article.id}&like=true`, requestOptions)
            .then((response) => response.text())
            .then((result) => {})
            .catch((error) => {
               return null;
            });
      } else {
         localStorage.removeItem(article.id + "_liked", "false");
         setArticle((prev) => ({ ...prev, liked: false, likes: prev.likes - 1 }));
         await fetch(`https://europe-west1-morrow-it-solutions-web.cloudfunctions.net/sendLike?article=${article.id}&like=false`, requestOptions)
            .then((response) => response.text())
            .then((result) => {})
            .catch((error) => {
               return null;
            });
      }
   };
   useEffect(() => {
      function handleClickOutside(e) {
         if (e.target.id !== "share-options" && e.target.parentNode.id !== "share-options" && e.target.className !== "shareOtp" && e.target.parentNode.className !== "shareOtp") {
            setShare(false);
         }
      }
      if (share) {
         document.addEventListener("click", handleClickOutside);
      } else {
         document.removeEventListener("click", handleClickOutside);
      }
      // Clean up the event listener on unmount or when the component is no longer in use
      return () => {
         document.removeEventListener("click", handleClickOutside);
      };
   }, [share]);
   return (
      <>
         {article && (
            <Helmet>
               <title>{article.title}</title>
               <meta name="description" content={article.content.find((path) => path.type === "paragraph").text || "Check out this article"} />
               <meta name="keywords" content="keyword1, keyword2, keyword3" />
               <meta name="author" content={article.author.name} />
               <meta property="og:title" content={article.title} />
               <meta property="og:description" content={article.content.find((path) => path.type === "paragraph").text || "Check out this article"} />
               <meta property="og:image" content={article.thumbnail_photo || "https://2morrowit.com/images/logo.svg"} />
               <meta name="twitter:card" content={article.thumbnail_photo || "https://2morrowit.com/images/logo.svg"} />
               <meta name="twitter:title" content={article.title} />
               <meta name="twitter:description" content={article.content.find((path) => path.type === "paragraph").text || "Check out this article"} />
               <meta name="twitter:image" content={article.thumbnail_photo || "https://2morrowit.com/images/logo.svg"} />
               {/* Add more meta tags as needed */}
            </Helmet>
         )}
         <div className="main">
            <img className="articDet1-img" src="/images/blob.svg" alt="" />
            <img className="articDet2-img" src="/images/blob.svg" alt="" />
            <img className="articDet3-img" src="/images/blob.svg" alt="" />

            <div className="article-container">
               {article && (
                  <>
                     <div className="headline-article">
                        <h1>{article.title}</h1>
                        <div className="topics">
                           {article.tags.map((tag) => (
                              <span key={tag} className="articleTag" onClick={() => navigate("/articles/" + tag)}>
                                 {tag}
                              </span>
                           ))}
                        </div>
                     </div>
                     <div className="article">
                        <div className="article-author">
                           <div className="author">
                              <img src={article.author.photo} alt="" />
                              <h4>{article.author.name}</h4>
                              <span>·</span>
                              <span className="article-date">{formatTimestamp(article.start_date.seconds)}</span>
                           </div>
                           <div className="article-stats">
                              <div className="like-count">
                                 <span>{article.likes !== 0 && article.likes}</span>{" "}
                                 <button
                                    onClick={(e) => {
                                       e.preventDefault();
                                       e.stopPropagation();
                                       likeArticle();
                                    }}
                                 >
                                    {article.liked ? <img src="/images/thumb_up_fill.svg" alt="" /> : <img src="/images/thumb_up.svg" alt="" />}
                                 </button>
                              </div>
                              {/* <div className="book">
                                 <button onClick={() => bookmarkPage()}>{article.booked ? <img src="/images/bookmark_fill.svg" alt="" /> : <img src="/images/bookmark.svg" alt="" />}</button>
                              </div> */}
                              <div className="share">
                                 <button
                                    onClick={(e) => {
                                       e.preventDefault();
                                       e.stopPropagation();
                                       setShare(!share);
                                    }}
                                 >
                                    <img src="/images/share.svg" alt="" />
                                 </button>
                              </div>
                              {share && (
                                 <div className="share-options" id="share-options">
                                    <button onClick={() => navigator.clipboard.writeText(window.location.href)} className="shareOtp">
                                       <img src="/images/content_copy.svg" alt="" />
                                       Copy Link
                                    </button>
                                    <a className="shareOtp" href={`https://www.linkedin.com/sharing/share-offsite/?url=${"2morrowit.com/article/" + articleId}&title=${encodeURIComponent(article.title)}`} target="_blank" rel="noopener noreferrer">
                                       <img src="/images/linkedin.svg" alt="" />
                                       Share on LinkedIn
                                    </a>
                                    <a
                                       className="shareOtp"
                                       href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(window.location.href)}&text=${encodeURIComponent(`${article.title}\n\n${article.content.find((path) => path.type === "paragraph").text || "Check out this article"}`)}`}
                                       target="_blank"
                                       rel="noopener noreferrer"
                                    >
                                       <img src="/images/X_logo.svg" alt="" />
                                       Share on Twitter
                                    </a>
                                    <a className="shareOtp" href={`https://www.facebook.com//sharer/sharer.php?u=${"2morrowit.com/article/" + articleId}&title=${article.title}`} target="_blank" rel="noopener noreferrer">
                                       <img src="/images/facebook.svg" alt="" />
                                       Share on Facebook
                                    </a>
                                 </div>
                              )}
                           </div>
                        </div>
                        {article.thumbnail_photo && (
                           <div className="article-thumbnail">
                              <img src={article.thumbnail_photo} alt="" />
                           </div>
                        )}
                        <div className="article-section">
                           {article.content.map((htmlTag, index) => {
                              switch (htmlTag.type) {
                                 case "heading":
                                    if (htmlTag.level === 1) {
                                       return <h1>{htmlTag.text}</h1>;
                                    } else if (htmlTag.level === 2) {
                                       return <h2>{htmlTag.text}</h2>;
                                    } else if (htmlTag.level === 3) {
                                       return <h3>{htmlTag.text}</h3>;
                                    } else if (htmlTag.level === 4) {
                                       return <h4>{htmlTag.text}</h4>;
                                    } else return <h5>{htmlTag.text}</h5>;
                                 case "paragraph":
                                    return (
                                       <p>
                                          <Linkify text={htmlTag.text} />
                                       </p>
                                    );
                                 case "unordered_list":
                                    return (
                                       <ul>
                                          {htmlTag.text.map((item) => (
                                             <li key={item}>
                                                <Linkify text={item} />
                                             </li>
                                          ))}
                                       </ul>
                                    );
                                 case "ordered_list":
                                    return (
                                       <ol>
                                          {htmlTag.text.map((item) => (
                                             <li key={item}>
                                                <Linkify text={item} />
                                             </li>
                                          ))}
                                       </ol>
                                    );
                                 case "image":
                                    return <img src={htmlTag.path} alt=""></img>;
                                 case "code":
                                    const formattedCode = htmlTag.text.join("\n");
                                    return (
                                       <div className="codeBlock">
                                          {htmlTag.text && (
                                             <CopyBlock
                                                key={"code_block_" + index} // Ensure each code block has a unique key
                                                text={formattedCode}
                                                customStyle={{
                                                   width: "100%",
                                                   overflow: "auto",
                                                   fontSize: "0.9rem",
                                                   borderRadius: "0.5rem",
                                                   padding: "1.2rem 2rem 1.2rem 0",
                                                }}
                                                theme={dracula}
                                                language={htmlTag.lang} // Specify the language of the code string (e.g., jsx)
                                                showLineNumbers={true} // Optional: Specify if you want to show line numbers
                                                wrapLines={true} // Optional: Specify if you want to wrap long lines
                                             />
                                          )}
                                       </div>
                                    );
                                 default:
                                    return null;
                              }
                           })}
                        </div>
                     </div>
                  </>
               )}
            </div>

            {!article && <img className="loadAny" src="/images/spinner.svg" alt="" />}
         </div>
         <Footer></Footer>
      </>
   );
};

export default Article;
