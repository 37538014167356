import { collection, onSnapshot } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db, storage } from "../../firebase/initStore";
import { getDownloadURL, ref } from "firebase/storage";
import AppDetails from "./appDetails";

const HomeB2B = () => {
   const [appList, setAppList] = useState([]);
   const [showApp, setShowApp] = useState(false);
   const appListRef = collection(db, "Customers", "J8CVSPO3yrNzUIqt0y8I", "Apps");

   useEffect(() => {
      const unsubscribeAppList = onSnapshot(appListRef, async (appsSnap) => {
         const fetchData = appsSnap.docs.map(async (appDoc) => {
            let iconRef = ref(storage, appDoc.data().icon);
            const url = await getDownloadURL(iconRef);
            // Fetch the attachment blob
            // eslint-disable-next-line no-unused-vars
            const response = await fetch(url, { mode: "no-cors", redirect: "follow" });
            return { ...appDoc.data(), id: appDoc.id, icon: url };
         });
         Promise.all(fetchData).then(async (newData) => {
            console.log(newData);
            setAppList(newData);
         });
      });
      return () => {
         unsubscribeAppList();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);
   return (
      <>
         <div className={showApp ? "mainStore mainActive" : "mainStore"}>
            <div className="header">
               <h2>Home</h2>
            </div>
            <div className="appList">
               {appList &&
                  appList.map((app) => (
                     <div
                        className="appCard"
                        onClick={() => {
                           setShowApp(app);
                        }}
                     >
                        <div className="applogo">
                           <img src={app.icon} alt="" />
                        </div>
                        <div className="appDetails">
                           <h3>{app.name} </h3>
                           <p>{app.shortDescription}</p>
                        </div>
                     </div>
                  ))}
               {appList.length === 0 && <img className="loadAny" src="/images/spinner.svg" alt="" />}
            </div>
         </div>
         <AppDetails app={showApp || null} close={() => setShowApp(null)} />
      </>
   );
};

export default HomeB2B;
