import React from "react";
import Footer from "../components/Footer";
import { NavLinka } from "../components/NavbarElements";
const Home = () => {
   return (
      <>
         <div className="main">
            <div className="hero-section">
               <div>
                  <h1>Developing complete software solutions.</h1>
                  <p>Are you ready to turn your ambition into reality? We are primed to provide the assistance you need. </p>
                  <div className="cta-Div">
                     <NavLinka
                        className="primaryBtn"
                        onClick={() => {
                           window.scroll(0, 0);
                        }}
                        to="/contact"
                        activeStyle
                     >
                        Get started
                     </NavLinka>
                     <NavLinka
                        to="/about"
                        onClick={() => {
                           window.scroll(0, 0);
                        }}
                        activeStyle
                     >
                        Learn more
                     </NavLinka>
                  </div>
               </div>
               <div className="images">
                  <img className="hero-img1" src="./images/blob.svg" alt="" />
                  <img className="hero-img2" src="./images/hero.svg" alt="" />
               </div>
               <div className="cta-Div">
                  <NavLinka
                     className="primaryBtn"
                     onClick={() => {
                        window.scroll(0, 0);
                     }}
                     to="/contact"
                     activeStyle
                  >
                     Get started
                  </NavLinka>
                  <NavLinka
                     to="/about"
                     onClick={() => {
                        window.scroll(0, 0);
                     }}
                     activeStyle
                  >
                     Learn more
                  </NavLinka>
               </div>
            </div>
         </div>
         <Footer></Footer>
      </>
   );
};

export default Home;
