import React from "react";
import Footer from "../components/Footer";
import { NavLinka } from "../components/NavbarElements";
import TeamMember from "../components/TeamMember";
import Testimonials from "../components/Testimonials";

const About = () => {
   return (
      <>
         <div className="main">
            <div className="about-section">
               <div>
                  <h1>Who are we?</h1>
                  <p>Founded in 2021, 2Morrow is a software consultancy company from Romania. We are a company that specializes in delivering software solutions in the mobile and web field.</p>
                  <p>
                     We can take your app or web ideas, bring them to life, and create the required infrastructure for them. We can guide you through the process of designing your product, explain the technical aspects, discuss topics with full transparency and clarity, to help you build your best
                     products.
                  </p>
               </div>

               <img className="about-img1" src="./images/about_us.svg" alt="" />
               <img className="about-img2" src="./images/blob.svg" alt="" />
            </div>
            <div className="about-short">
               <h1>Our vision</h1>
               <p>Making great ideas come to reality, and we do our best to ensure that each product we deliver is fast, scalable, reliable, secure and visually appealing.</p>
               <p>
                  Our purpose is to deliver a great experience for our customers, excellent communication and great results backed by giving insights into our progress and the respective results. We are always happy to talk with more people and see what they are passionate about creating. That's
                  why, if you want to bring your ideas to life, you can reach us at our contact page.
               </p>
               <div className="cta-Div cta-about">
                  <NavLinka
                     className="primaryBtn"
                     onClick={() => {
                        window.scroll(0, 0);
                     }}
                     to="/contact"
                     activeStyle
                  >
                     Contact us
                  </NavLinka>
               </div>
            </div>
            <Testimonials></Testimonials>

            <div className="our-team">
               <div className="headline">
                  <h1>Our Team</h1>
                  <p>
                     At 2Morrow, we have a talented team of professionals who are passionate about delivering exceptional results. Our team combines expertise from various disciplines to create innovative solutions and provide unparalleled services. Get to know the talented individuals who make up
                     our team:
                  </p>
               </div>
               <div className="team">
                  <TeamMember
                     srcImg="./images/alex_cehan_cv_small.jpg"
                     name="Alexandru Cehan"
                     title="Android Developer & Founder"
                     description="I have a strong enthusiasm for mobile development and all its associated aspects, with a particular focus on Android as my primary expertise."
                     anchor0="mailto:alex.cehan@2morrowit.com"
                     anchor0type="email"
                     anchor1="https://github.com/alex-cehan"
                     anchor1type="github"
                     anchor2="https://www.linkedin.com/in/alexandrucehan/"
                     anchor2type="linkedin"
                     anchor3="https://alexcehan.substack.com/"
                     anchor3type="website"
                  ></TeamMember>
                  <TeamMember
                     srcImg="./images/dragos_ciot_cv_small.jpg"
                     name="Dragos Georgian Ciot"
                     title="iOS Developer & Co-Founder"
                     description="I strive to develop iOS apps that are visually appealing, while ensuring optimal utility, functionality, and code cleanliness."
                     anchor0="mailto:george@2morrowit.com"
                     anchor0type="email"
                     anchor1="https://github.com/dragos-ciot"
                     anchor1type="github"
                     anchor2="https://www.linkedin.com/in/dragosciot/"
                     anchor2type="linkedin"
                  ></TeamMember>
                  <TeamMember
                     srcImg="./images/alex_tiron_cv_small.jpg"
                     name="Alexandru Bogdan Tiron"
                     title="Front-End Developer & Co-Founder"
                     description="I'm all about crafting awesome websites. From dynamic interfaces to user-friendly experiences, I bring passion to every project."
                     anchor0="mailto:alex.tiron@2morrowit.com"
                     anchor0type="email"
                     anchor1="https://github.com/alex-tiron"
                     anchor1type="github"
                     anchor2="https://www.linkedin.com/in/alextiron/"
                     anchor2type="linkedin"
                  ></TeamMember>
                  <TeamMember
                     srcImg="./images/alex_costin_cv_small.jpg"
                     name="Alexandru Costin"
                     title="Backend Developer"
                     description="Enthusiast about creating efficient and scalable backend systems. I enjoy staying up-to-date with the latest technologies and best practices."
                     anchor0="mailto:alex.costin@2morrowit.com"
                     anchor0type="email"
                     anchor1="https://github.com/alex-costin"
                     anchor1type="github"
                     anchor2="https://www.linkedin.com/in/alexandru-costin-63058b169/"
                     anchor2type="linkedin"
                  ></TeamMember>
                  <TeamMember
                     srcImg="./images/alex_cretu_cv_small.jpg"
                     name="Alexandru Crețu"
                     title="Junior Android Developer"
                     description="With a burning passion for developing in Android Studio, I love creating engaging apps. With every line of code, I bring ideas to life and solve complex problems."
                     anchor0="mailto:alex.cretu@2morrowit.com"
                     anchor0type="email"
                     anchor1="https://github.com/alexandrucretu1"
                     anchor1type="github"
                     anchor2="https://www.linkedin.com/in/alexandru-cre%C8%9Bu-a9a707249/"
                     anchor2type="linkedin"
                  ></TeamMember>
               </div>
            </div>
         </div>
         <Footer></Footer>
      </>
   );
};

export default About;

//<div className="redirect-short">
//       <div className="redirect-banner">
//    <div className="rdr-left">
//       <h2>More about us</h2>
//       <p>Striving to ensure our products meet the highest quality standards possible, are optimized for your needs, fast and good-looking.</p>
//    </div>
//    <div className="rdr-right">
//       {/* <div className="rdr-linkedin"></div> */}
//       <div className="cta-Div">
//          <NavLinka to="/help" activeStyle>
//             Need Guidance?
//          </NavLinka>
//          <NavLinka className="primaryBtn" to="/contact" activeStyle>
//             Contact us
//          </NavLinka>
//       </div>
//    </div>
// </div>
//            </div>
