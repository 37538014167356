import "./css/App.css";
import React from "react";
import { Nav } from "./components/NavbarElements";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages";
import About from "./pages/about";
import Portofolio from "./pages/portofolio";
import Help from "./pages/help";
import Contact from "./pages/contact";
import Terms from "./pages/terms";
import Privacy from "./pages/privacy";
import ArticlesList from "./pages/articlesList";
import Article from "./pages/article";
import Login from "./pages/login";
import AddArticle from "./pages/addArticle";
import StoreApp from "./pages/storeB2B/storeApp";
function App() {
   // try {
   //    let id = sessionStorage.getItem("id");
   //    if (!id)
   //       fetch(`https://europe-west1-morrow-it-solutions-web.cloudfunctions.net/sendLog`).then((res) => {
   //          if (res.ok) {
   //             sessionStorage.setItem("id", true);
   //          } else {
   //             sessionStorage.setItem("id", false);
   //          }
   //       });
   // } catch (error) {}

   return (
      <Router>
         {!window.location.pathname.includes("/storeB2B") && !window.location.pathname.includes("/StoreB2B") && <Nav />}
         <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/portofolio" element={<Portofolio />} />
            <Route path="/articles" element={<ArticlesList />} />
            <Route path="/articles/:tagId" element={<ArticlesList />} />
            <Route path="/article/:articleId" element={<Article />} />
            <Route path="/help" element={<Help />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/login" element={<Login />} />
            <Route path="/addArticle" element={<AddArticle />} />
            <Route path="/StoreB2B/*" element={<StoreApp />} />
         </Routes>
      </Router>
   );
}

export default App;
