// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
   apiKey: "AIzaSyAPkR77HCGYDuYXSVsIW8pn0-p6hA6HfQE",
   authDomain: "store-b2b-development.firebaseapp.com",
   databaseURL: "https://store-b2b-development-default-rtdb.europe-west1.firebasedatabase.app",
   projectId: "store-b2b-development",
   storageBucket: "store-b2b-development.appspot.com",
   messagingSenderId: "543219363050",
   appId: "1:543219363050:web:1dbea51a576afa02413b93",
   measurementId: "G-7M6MH57MER",
};

// Initialize Firebase
const appStore = initializeApp(firebaseConfig, "appStore");
// const analytics = getAnalytics(app);
export default appStore;

export const auth = getAuth(appStore);
export const db = getFirestore(appStore);
export const rtdb = getDatabase(appStore);
export const storage = getStorage(appStore);
