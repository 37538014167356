import React from "react";
import { NavLink as Link } from "react-router-dom";

const Footer = () => {
   return (
      <div className="footer">
         <div className="logo-footer">
            <div>
               <Link
                  to="/"
                  onClick={() => {
                     window.scroll(0, 0);
                  }}
               >
                  <img src="/images/logo.svg" alt="logo"></img>
               </Link>
               <span>Developing complete software solutions.</span>
            </div>
            <div className="terms-info">
               <span>© {new Date().getFullYear()} 2Morrow IT Solutions SRL • </span>
               <Link
                  to="/privacy"
                  onClick={() => {
                     window.scroll(0, 0);
                  }}
               >
                  Privacy
               </Link>
               <span> • </span>
               <Link
                  to="/terms"
                  onClick={() => {
                     window.scroll(0, 0);
                  }}
               >
                  Terms
               </Link>
               <span> • </span>
            </div>
         </div>

         <div className="contact-footer">
            <h3>Follow us</h3>
            <div className="flw-img">
               <a href="https://github.com/2Morrow-IT-Solutions" target="_blank" rel="noopener noreferrer">
                  <img className="git" src="/images/github.svg" alt="" />
               </a>
               <a href="https://www.linkedin.com/company/2morrow-it/" target="_blank" rel="noopener noreferrer">
                  <img className="linked" src="/images/linkedin.svg" alt="" />
               </a>
            </div>
            <span>Galati, Romania</span>
            <a href="mailto:contact@2morrowit.com">contact@2morrowit.com</a>
            {/* <a href="tel:+40712345678">+40712345678</a> */}
         </div>
      </div>
   );
};

export default Footer;
